import "bootstrap/dist/css/bootstrap.min.css";
import React from 'react';
import ReactDOM from "react-dom";

import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import configureStore from "./redux/store";
import { Provider as ReduxProvider } from "react-redux";

const store = configureStore();

ReactDOM.render(
    <ReduxProvider store={store}>
        <Router>
            <App/>
        </Router>
    </ReduxProvider>,

    document.getElementById("root")
);
