import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { batchFileState } from '../../constants/batchFileConstants';
import { faPlayCircle, faStopCircle, faPauseCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddReasonModal } from '../Modals/AddReasonModal';
import { ContinueOrCancelModal } from '../Modals/ContinueOrCancelModal'
import { getBatchFileId, putBatchFileStatus } from '../../api/bulkAPI';
import * as functionUtils from "../../functions/functionUtils";
import Pagination from "react-js-pagination";
import { SelectInput } from "../FormComponents/FormFields";
import { useTranslation } from 'react-i18next';

function BatchFileTable(props) {
    const { t } = useTranslation();
    const [playModalIsOpen, setPlayModalIsOpen] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [putBody, setPutBody] = useState({});
    const [batchFileId, setbatchFileId] = useState({});
    const [dataModal, setdataModal] = useState({
        titleModal: null,
        messageModal: null
    });
    const total = props.batchFiles.total;
    const hiddenPagination = props.batchFiles.results.length === 0;
    const from = ((props.batchFiles.page - 1) * props.batchFiles.pageSize) + 1;
    const to = from + (props.batchFiles.results == null ? 0 : (props.batchFiles.results.length - 1));
    const totalNumberPages = Math.ceil(total / props.pageSize);

    function togglePlayModal(batchFileId) {
        if (!playModalIsOpen) {
            setbatchFileId(batchFileId);
            setPutBody({
                batchFileStateId: batchFileState.RESUME_PROCCESSING,
                createdBy: props.userId,
                noteId: null,
                noteContent: null
            });
        }
        setPlayModalIsOpen(!playModalIsOpen);
    };

    function savePlay(event) {
        putBatchFileStatus(batchFileId, putBody)
            .then(res => { if (res) handleSaveSuccess() })
            .catch(error => functionUtils.handleError(error));

        setPlayModalIsOpen(!modalIsOpen);
    };

    function handleSaveSuccess() {
        props.callTypeSearch();
        functionUtils.handleSuccess(t("message.saveReasonSuccessfull"))
    }

    function toggleModal(batchFileId, type) {
        if (!modalIsOpen) {
            if (type === "stop") {
                setdataModal({
                    titleModal: t("title.areYouSureStop"),
                    messageModal: t("message.addInformationToStop")
                })
            }
            else {
                setdataModal({
                    titleModal: t("title.areYouSurePause"),
                    messageModal: t("message.addInformationToPause")
                })
            }

            getBatchFileId(batchFileId)
                .then(_item => { handleNotesResponse(functionUtils.getfirstArray(_item.notes), type) })
                .catch(error => functionUtils.handleError(error));

            setbatchFileId(batchFileId);
        }

        setModalIsOpen(!modalIsOpen);
    };

    const handleNotesResponse = (notes, type) => {
        return setPutBody(prev => ({
            ...prev,
            noteId: notes !== undefined ? notes.noteId : null,
            noteContent: notes !== undefined ? notes.noteContent : null,
            batchFileStateId: type === "stop" ? batchFileState.STOPPED : batchFileState.PAUSED,
            createdBy: props.userId
        }));
    };

    function saveReason(event) {
        putBatchFileStatus(batchFileId, putBody)
            .then(res => { if (res) handleSaveSuccess() })
            .catch(error => functionUtils.handleError(error));

        setModalIsOpen(!modalIsOpen);
        props.callTypeSearch();
    };

    function handleChange(event, nameOfComponent) {
        const { name, value } = event.target;
        setPutBody(prev => ({
            ...prev, [name]: value,
        }));
    };

    const options = [
        { value: 10, label: t("selectInput.10Rows") },
        { value: 25, label: t("selectInput.25Rows") },
        { value: 50, label: t("selectInput.50Rows") },
        { value: 100, label: t("selectInput.100Rows") }
    ];

    return (
        <>
            <div className="panel panel-default">
                <div className="panel-body">
                    <div className="dataTables_length width-Select-rows" hidden={hiddenPagination}>
                        <SelectInput
                            name={"pageSize"}
                            defaultOption={"Select Template Type"}
                            onChange={props.handlePageSizeChange}
                            options={options}
                            selected={props.pageSize}
                        />
                    </div>
                    <div>
                        <div className="table-responsive">
                            <table className='table table-bordered table-striped' aria-labelledby="tabelLabel">
                                <thead className="#bbdefb blue lighten-3 headerTableText breakWhiteSpace">
                                    <tr>
                                        <th>{t("dataTable.fileManagement.batchFileName")}</th>
                                        <th>{t("dataTable.fileManagement.typeFile")}</th>
                                        <th>{t("dataTable.fileManagement.addedOn")}</th>
                                        <th>{t("dataTable.fileManagement.addedBy")}</th>
                                        <th>{t("dataTable.fileManagement.scope")}</th>
                                        <th>{t("dataTable.fileManagement.status")}</th>
                                        <th className="td-action">{t("dataTable.action")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.batchFiles.results.length === 0 ? (
                                        <tr><td colSpan="7" className="dataTables_empty">{t("dataTable.empty")}</td></tr>
                                    ) :
                                    (props.batchFiles.results.map(batchFile =>
                                        <tr key={batchFile.batchFileId}>
                                            <td>{batchFile.batchFileName}</td>
                                            <td>{t(batchFile.templateTypeName)}</td>
                                            <td>{functionUtils.formatDateTime(batchFile.addedOn)} </td>
                                            <td>{batchFile.addedByName}</td>
                                            <td>{batchFile.scopeName}</td>
                                            <td>{t(batchFile.batchFileStateName)}</td>
                                            {props.readOnlyPermission ?
                                                <td>
                                                    <div className="tooltip-wrapper tooltip-wrapper-without-margen">
                                                        <div className="tooltip-wrapper tooltip-wrapper-without-margen" data-toggle="tooltip" data-placement="top" title={t("inputField.fileInfo")}>
                                                            <Link className="ico-color-blue btn btn-floating-custom" to={`/fileManagement/${batchFile.batchFileId}`}><FontAwesomeIcon icon={faInfoCircle} /></Link>
                                                        </div>
                                                    </div>
                                                </td>
                                             :
                                                <td>
                                                    <div className="tooltip-wrapper tooltip-wrapper-without-margen">
                                                        <div className="tooltip-wrapper tooltip-wrapper-without-margen" data-toggle="tooltip" data-placement="top" title={t("inputField.fileInfo")}>
                                                            <Link className="ico-color-blue btn btn-floating-custom" to={`/fileManagement/${batchFile.batchFileId}`}><FontAwesomeIcon icon={faInfoCircle} /></Link>
                                                        </div>
                                                    </div>
                                                    <div className="tooltip-wrapper tooltip-wrapper-without-margen">
                                                        <div className="tooltip-wrapper tooltip-wrapper-without-margen" data-toggle="tooltip" data-placement="top" title={t("inputField.play")}>
                                                            <button className="ico-color-green btn btn-floating-custom" disabled={batchFile.batchFileStateId.toUpperCase() !== batchFileState.PAUSED}
                                                                onClick={e => togglePlayModal(batchFile.batchFileId)}>
                                                                <FontAwesomeIcon icon={faPlayCircle} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="tooltip-wrapper tooltip-wrapper-without-margen">
                                                        <div className="tooltip-wrapper tooltip-wrapper-without-margen" data-toggle="tooltip" data-placement="top" title={t("inputField.pause")}>
                                                            <button className="ico-color-gold btn btn-floating-custom" disabled={(batchFile.batchFileStateId.toUpperCase() !== batchFileState.IN_PROGRESS) && (batchFile.batchFileStateId.toUpperCase() !== batchFileState.RESUME_PROCCESSING)}
                                                                onClick={e => toggleModal(batchFile.batchFileId, "pause")}>
                                                                <FontAwesomeIcon icon={faPauseCircle} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="tooltip-wrapper tooltip-wrapper-without-margen">
                                                        <div className="tooltip-wrapper tooltip-wrapper-without-margen" data-toggle="tooltip" data-placement="top" title={t("inputField.stop")}>
                                                            <button className="ico-color-red btn btn-floating-custom" disabled={(batchFile.batchFileStateId.toUpperCase() !== batchFileState.IN_PROGRESS) && (batchFile.batchFileStateId.toUpperCase() !== batchFileState.RESUME_PROCCESSING) && (batchFile.batchFileStateId.toUpperCase() !== batchFileState.THREAD_WITH_ERRORS)}
                                                                onClick={e => toggleModal(batchFile.batchFileId, "stop")}>
                                                                <FontAwesomeIcon icon={faStopCircle} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div hidden={hiddenPagination}>
                            {`${t("dataTable.footer.showing")} `} {from} {` ${t("dataTable.footer.to")} `} {to} {` ${t("dataTable.footer.of")} `} {total} {` ${t("dataTable.footer.entries")}`}
                            <div className="pagination pull-right">
                                <div className="pagination pull-right">
                                    <div className="row">
                                        <label>{`${t("dataTable.pagination.page")} `}</label>
                                        <input
                                            className="form-control width-Select-rows"
                                            type={"number"}
                                            name={"goToPage"}
                                            value={props.activePage}
                                            onChange={props.handleGoToPageChange}
                                            min={1}
                                            max={totalNumberPages}
                                        />
                                        <Pagination
                                            hideNavigation
                                            firstPageText={t("dataTable.pagination.first")}
                                            lastPageText={t("dataTable.pagination.last")}
                                            activePage={props.activePage}
                                            itemsCountPerPage={props.pageSize}
                                            totalItemsCount={total}
                                            pageRangeDisplayed={props.pageSize}
                                            onChange={props.handlePageChange}
                                            pageRangeDisplayed={5}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContinueOrCancelModal
                toggle={togglePlayModal}
                isOpen={playModalIsOpen}
                title={t("title.areYouSure")}
                message={t("message.areYouSureYouWantToPlay")}
                onClick={savePlay}
            />
            <AddReasonModal
                toggle={toggleModal}
                isOpen={modalIsOpen}
                title={dataModal.titleModal}
                message={dataModal.messageModal}
                value={putBody.noteContent != null ? putBody.noteContent : ""}
                name={"noteContent"}
                label={t("button.save")}
                handleChange={handleChange}
                onClick={saveReason}
            />
        </>
    );
}

export default BatchFileTable;

