export default {
    configuration: [],
    batchFileSummary: {},
    batchFiles: {
        page: 1,
        total: 0,
        results: []
    },
    templateTypes: [],
    templates: [],
    batchFileStatus: [],
    identityUsers: [],
    scopes: [],
    summary: {},
    batchFile: [],
    batchFileProcessed: {
        page: 1,
        total: 0,
        rowsWithErrors: 0
    },
    apiCallsInProgress: 0,
    roles: [],
    rolesUser: []
};
