import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export const PageAccessDenied = ({ accessDenied, ...props }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="errorPages">
                <h1 className="text-danger">{t("error.accessDenied")}</h1>
                <h4 className="text-danger">{t("error.notHaveAccess")} {t("header." + props.match.params.urlDenied)}</h4>
                <p>
                    <Link to="/">{t("button.backToHome")}</Link>
                </p>
            </div>
        </>
    );
};