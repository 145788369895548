import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export const PageNotFound = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="errorPages">
                <h1>404</h1>
                <h4>{t("error.pageNotFound")}</h4>
                <p>{t("error.pageNotExist")}</p>
                <p>
                    <Link to="/">{t("button.backToHome")}</Link>
                </p>
            </div>
        </>
    );
};

export const LoadingError = () => {
    const { t } = useTranslation();
    const refreshPage = () => {
        window.location.reload(false);
    };

    return (
        <>
            <div style={{ position: "height 100%" }}>
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                        fontWeight: "100",
                        color: "rgb(88,88,88)"
                    }}
                >
                    <h4>{t("error.contentFailedToLoad")}</h4>
                    <p>{t("error.errorLoadingPage")}</p>
                    <button className="btn btn-link" onClick={refreshPage}>
                        {t("button.tryAgain")}
                    </button>
                </div>
            </div>
        </>
    );
};

export const NoResults = () => {
    const { t } = useTranslation();
    return (
        <div style={{ position: "height 100%" }}>
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                    fontWeight: "100",
                    color: "rgb(88,88,88)"
                }}
            >
                <h4>{t("error.noResultsFound")}</h4>
                <p></p>
            </div>
        </div>
    )
}