import Fetch from "./fetchUtils"
const rootUrl = 'api/bulk';

export function getSummary() {
    return Fetch.get(`${rootUrl}/summary`);
}

export function getBulkImporterSearch(params) {
    return Fetch.get(`${rootUrl}/search`, params);
}

export function postBulkImporterAdvancedSearch(params) {
    return Fetch.post(`${rootUrl}/advancedsearch`, params);
};

export function getBatchFileStatus() {
    return Fetch.get(`${rootUrl}/states`);
}

export function putBatchFileStatus(batchFileId, params) {
    return Fetch.put(`${rootUrl}/${batchFileId}/state`, params);
}

export function getBatchFileId(batchFileId) {
    return Fetch.get(`${rootUrl}/${batchFileId}`);
}

export function getBatchFileProcessed(batchFileId, params) {
    return Fetch.get(`${rootUrl}/${batchFileId}/processed`, params);
}

export function getBatchFileIncorrectsProcess(batchFileId, proccessId) {
    return Fetch.get(`${rootUrl}/${batchFileId}/incorrects/${proccessId}`);
}

export function getRoles() {
    return Fetch.get(`${rootUrl}/roles`);
}

export function getRolesByUserId(subjectId) {
    return Fetch.get(`${rootUrl}/${subjectId}/roles`);
}
