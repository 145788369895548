import React, { useState, useEffect, useContext } from 'react';
import { connect } from "react-redux";
import { loadTemplateTypes, loadTemplates, uploadedTemplates } from "../../redux/actions/templateActions";
import { loadScopes } from "../../redux/actions/serviceManagerActions";
import * as functionUtils from "../../functions/functionUtils"
import { InputField, InputFile, SelectInput, TreeView, ReactTable } from "../FormComponents/FormFields";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTemplateByTemplateId } from '../../api/templateAPI';
import { CircleLoader } from "../PageFillers/PageLoaders";
import PropTypes from "prop-types";
import { templateState } from '../../constants/batchFileConstants'
import { AuthContext } from '../Auth/AuthContext';
import { TitleComponent } from '../common/Title';
import { useTranslation } from 'react-i18next';

function TemplateManagement({
    templates,
    templateTypes,
    scopes,
    loadTemplateTypes,
    loadScopes,
    loadTemplates,
    uploadedTemplates
}) {
    const { t } = useTranslation();
    const authService = useContext(AuthContext);
    const [errors, setErrors] = useState({});
    const [userId, setUserId] = useState(authService.getUserId());
    const [postBody, setPostBody] = useState({});
    const [uploading, setUploading] = useState(false);
    const [check, setCheck] = useState({});
    let base64String = "";
    const allowedExtensions = /(\.xls|\.xlsx)$/i;
    const loading = templates.length === 0;

    useEffect(() => {
        handleFetchTemplatesSearch();
        handleFetchTemplateTypesResponse();
        handleFetchScopesResponse();
        setUserId(authService.getUserId());
    }, []);

    const handleFetchTemplatesSearch = () => {
        if (templates.length === 0)
            loadTemplates();
    }

    const handleFetchTemplateTypesResponse = () => {
        if (templateTypes.length === 0)
            loadTemplateTypes();
    };

    const handleFetchScopesResponse = () => {
        if (scopes.length === 0)
            loadScopes();
    };

    function formIsValid() {
        const errors = {};

        if (!postBody.name) errors.fileUpload = t("error.templateFileCannotBeEmpty");
        if (!postBody.templateTypeId) errors.templateTypeId = t("error.templateTypeCannotBeEmpty");
        if (!postBody.scopeId) errors.scopeId = t("error.scopeCannotBeEmpty");
        if (!postBody.description) errors.description = t("error.descriptionCannotBeEmpty");

        setErrors(errors);

        // Form is valid if the errors object still has no properties
        return Object.keys(errors).length === 0;
    }

    function extensionsIsValid(fileName) {
        if (!allowedExtensions.exec(fileName)) {
            functionUtils.handleWarning(t("warning.uploadFileMustBeXlsOrXlsx"));
            return true;
        }
        return false
    }

    const handleFileUploadChange = (event, nameOfComponent) => {
        let fileName = event.target.files[0].name;

        if (extensionsIsValid(fileName)) return;

        let f = event.target.files[0];
        let reader = new FileReader();

        reader.onload = (function () {
            return function (e) {

                var binaryData = e.target.result;
                base64String = window.btoa(binaryData);
                setPostBody({
                    fileUpload: fileName,
                    name: fileName,
                    templateStateId: templateState.ACTIVE,
                    description: "",
                    uploadedBy: userId,
                    file: base64String
                });
            };
        })(f);
        reader.readAsBinaryString(f);
    };

    const onClickUploadFile = (event) => {
        if (!formIsValid()) return;
        setUploading(true);

        uploadedTemplates(postBody)
            .then(res => handleUploadedTemplate(res))
            .catch((error) => {
                setUploading(false);
                setErrors({ onSave: error.message });
            });
    };

    const handleUploadedTemplate = (res) => {
        if (res) {
            restartValues();
            loadTemplates();
            functionUtils.handleSuccess(t("message.saveFileUploadedSuccessfull"));
        }
        setUploading(false);
    }

    function restartValues() {
        setPostBody({
            fileUpload: "",
            name: "",
            templateTypeId: "",
            scopeId: "",
            description: ""
        });
        setCheck(prev => ({ ...prev, checkId: [] }));
    }

    function handleChange(event, nameOfComponent) {
        if (nameOfComponent !== undefined) {
            let value = event.value;
            setPostBody(prev => ({
                ...prev, [nameOfComponent.name]: value,
            }));
        }
        else {
            const { name, value } = event.target;
            setPostBody(prev => ({
                ...prev, [name]: value,
            }));
        }
    }

    const handleScopeChecked = event => {
        setCheck.checkId = [];
        setPostBody.scopeId = "";
        let value = event;
        setCheck(prev => {
            return { ...prev, checkId: value };
        });
        setPostBody(prev => ({ ...prev, scopeId: value[0] }));
    };

    const uncheckAllTreeView = event => {
        setCheck(prev => ({ ...prev, checkId: [] }));
        setPostBody(prev => ({ ...prev, scopeId: "" }));
    };

    const getTemplate = (templateId) => {
        getTemplateByTemplateId(templateId)
            .then(_item => downloadTemplate(_item))
            .catch(error => functionUtils.handleError(error));
    };

    const downloadTemplate = (template) => {
        const cadSplit = template.templateTypeName.split(".");
        const fileName = template.name;
        const fileExtension = cadSplit[1];
        let contentType = "";

        switch (fileExtension) {
            case ("xls"):
                contentType = "application/vnd.ms-excel;base64";
                break;
            case ("xlsx"):
                contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                break;
            default:
                contentType = "application/vnd.ms-excel;base64";
                break;
        }

        base64String = template.template;
        const sliceSize = 512;

        const byteCharacters = window.atob(base64String);
        let byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {
            type: contentType
        });

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                columns: [
                    {
                        Header: t("dataTable.templateManagement.templateName"),
                        accessor: 'name',
                    },
                    {
                        Header: t("dataTable.templateManagement.typeFile"),
                        accessor: 'typeName',
                    },
                    {
                        Header: t("dataTable.templateManagement.scope"),
                        accessor: 'scopeName',
                    },
                    {
                        Header: t("dataTable.templateManagement.uploadedBy"),
                        accessor: 'uploadedByName',
                    },
                    {
                        Header: t("dataTable.templateManagement.uploadedOn"),
                        id: "uploadedOn",
                        accessor: d => {
                            return functionUtils.formatDateTime(d.uploadedOn)
                        }
                    },
                    {
                        Header: t("dataTable.action"),
                        accessor: 'templateId',
                        width: "5%",
                        Cell: props => (
                            <div className="tooltip-wrapper tooltip-wrapper-without-margen">
                                <div className="tooltip-wrapper tooltip-wrapper-without-margen" data-toggle="tooltip" data-placement="top" title={t("inputField.downloadFile")}>
                                    <button className="ico-color-blue btn btn-floating-custom" onClick={e => getTemplate(props.value)}>
                                        <FontAwesomeIcon icon={faDownload} />
                                    </button>
                                </div>
                            </div>
                        )
                    }
                ],
            }
        ],
        []
    )

    return (
        <>
            {loading ? (
                <CircleLoader />

            ) : (
                    <>
                        <TitleComponent title={t("header.templateManagement")} />
                        <fieldset className="scheduler-border border rounded col-md-12 m-0 form-group">
                            <legend className="scheduler-border subsection">{t("legend.templatesUpload")}</legend>
                            <div className="row">
                                <div className="col-md-12">
                                    <InputFile
                                        placeholder={postBody.fileUpload || t("inputField.selectYourLocalFile")}
                                        name={"fileUpload"}
                                        label={t("inputField.templateFile")}
                                        buttonText={t("button.chooseFile")}
                                        handleChange={handleFileUploadChange}
                                        error={errors.fileUpload}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <fieldset className="col-md-4 m-0 form-group">
                                    <SelectInput
                                        label={t("inputField.typeFile")}
                                        name={"templateTypeId"}
                                        value={postBody.templateTypeId || ""}
                                        defaultOption={"Select Template Type"}
                                        onChange={handleChange}
                                        options={functionUtils.translateSelectInput(templateTypes, t)}
                                        selected={postBody.templateTypeId || ""}
                                        error={errors.templateTypeId}
                                    />
                                </fieldset>
                                <fieldset className="col-md-8 m-0 form-group">
                                    <InputField
                                        placeholder={" "}
                                        label={t("inputField.description")}
                                        name={"description"}
                                        value={postBody.description}
                                        handleChange={handleChange}
                                        error={errors.description}
                                    />
                                </fieldset>
                            </div>
                            <div className="row">
                                <fieldset className="col-md-6 m-0 form-group">
                                    <TreeView
                                        uncheckAllTreeView={uncheckAllTreeView}
                                        label={t("inputField.scopes")}
                                        name={"scopeId"}
                                        nodes={scopes}
                                        checked={check.checkId}
                                        onCheck={handleScopeChecked}
                                        checkNodesRecursive={false}
                                        multiScope={false}
                                        error={errors.scopeId}
                                    />
                                </fieldset>
                            </div>
                            <div className="row">
                                <fieldset className="col-md-12 m-0 p-0 form-group">
                                    <div className="md-form m-0 pull-right clearfix">
                                        <button className="btn btn-primary" onClick={onClickUploadFile} disabled={uploading}>{uploading ? t("button.uploading") : t("button.upload")}</button>
                                    </div>
                                </fieldset>
                            </div>
                        </fieldset>

                        <fieldset className="scheduler-border border rounded col-md-12 m-0 form-group">
                            <legend className="scheduler-border subsection">{t("legend.templatesVersions")}</legend>
                            <ReactTable columns={columns} data={templates.results} />
                        </fieldset>
                    </>
                )
            }
        </>
    );
}

TemplateManagement.propTypes = {
    templateTypes: PropTypes.array.isRequired,
    loadTemplateTypes: PropTypes.func.isRequired,
    loadTemplates: PropTypes.func.isRequired,
    loadScopes: PropTypes.func.isRequired,
    uploadedTemplates: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const templateTypes = state.templateTypes.length !== 0 ? functionUtils.parseSelectOptions(state.templateTypes) : [];
    const scopes = state.serviceManager.length !== 0 ? functionUtils.parseCheckTreeScopes(state.serviceManager) : [];
    return {
        templates: state.template,
        templateTypes,
        scopes
    };
}

const mapDispatchToProps = {
    loadTemplateTypes,
    loadTemplates,
    loadScopes,
    uploadedTemplates
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateManagement);