import * as types from "./actionTypes";
import * as identityAPI from "../../api/identityAPI";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { handleWarning  } from "../../functions/functionUtils";

export function loadIdentityUsersSuccess(identityUsers) {
    return { type: types.LOAD_IDENTITY_USERS_SUCCESS, identityUsers };
}

export function loadIdentityUsers(t) {
    return function (dispatch) {
        dispatch(beginApiCall());
        return identityAPI
            .getIdentityUsers(error => error.code === 403 ? handleWarning(t("warning.permissionAccessUsers")): error)
            .then(identityUsers => {
                dispatch(loadIdentityUsersSuccess(identityUsers));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}
