import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function bulkSearchReducer(state = initialState.batchFiles, action) {
    switch (action.type) {
        case types.LOAD_BULK_IMPORTER_SEARCH_SUCCESS:
            return action.batchFiles;
        case types.LOAD_BULK_IMPORTER_ADVANCED_SEARCH_SUCCESS:
            return action.batchFiles;
        default:
            return state;
    }
}
