import React from 'react';
import { Container } from 'reactstrap';
import Header from './common/Header';
import Footer from './common/Footer';
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
import { useTranslation } from 'react-i18next';



export default function Layout({ children, showBreadcrumb }) {

    const { t } = useTranslation();

    const routes = {
        '/': t("header.home"),
        '/fileManagement': t("header.fileManagement"),
        '/fileManagement/:batchFileId': t("header.batchFileDetail"),
        '/configuration': t("header.configuration")
    };

    return (
        <div>
            <Header />
            {showBreadcrumb ? <Breadcrumbs mappedRoutes={routes} /> : null}

            <Container>
                {children}
            </Container>
            <Footer />
        </div >
    );
}