import Fetch from './fetchUtils';
const rootUrl = 'connect/authorize';

export default class AuthorizationApi {
    /**
     * @returns {Promise<{url: string}>}
     */
    async getAuthorization() {
        const res = await Fetch.get(rootUrl, undefined, true);
        return res;
    }

    /**
     * @returns {Promise<{url: string}>}
     */
    async getEndSession() {
        const res = await Fetch.get(`${rootUrl}/logout`, undefined, true);
        return res;
    }

    /**
     * @param {string} redirectUrl
     * @returns {Promise<{url: string}>}
     */
    async getChangePassword(redirectUrl) {
        const res = await Fetch.get(`${rootUrl}/changepassword`, { redirectUrl });
        return res;
    }
}