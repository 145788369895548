import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Auth/AuthContext";
import { Redirect, useLocation } from "react-router-dom";
import { CircleLoader } from "../PageFillers/PageLoaders";

/**
 * 
 * @param {string} hash 
 */
const hashParse = (hash) => hash !== null ? hash.substr(1).split('&').map(x => x.split('=')).reduce((p, c) => {
    p[c[0].replace('/', '')] = c[1];
    return p;
}, {}) : null;

export default function SignIn() {
    const authService = useContext(AuthContext);
    const { hash } = useLocation();
    const [wait, setWait] = useState(true);
    const [isAuth, setIsAuth] = useState(authService.isAuthenticated());
    const redirectUrl = localStorage.getItem('signin_url');

    useEffect(() => {
        if (hash === null || hash === undefined) setWait(false);
        else {
            const parsedHash = /** @type {{ code: string, id_token: string }} */ (hashParse(hash));
            authService.signIn(parsedHash.code, parsedHash.id_token).then(() => {
                setIsAuth(authService.isAuthenticated());
                setWait(false);
            });
        }
    }, []);

    return wait ? <CircleLoader /> : <Redirect to={isAuth ? (redirectUrl !== null ? redirectUrl : "/") : "/login"} />;
}
