import React from 'react';

/**
 *
 * @param {Object} data
 * @param {Object} data.content
 * @param {string} data.content.title
 * @param {string} data.content.body
 * @param {string} [data.content.traceId]
 */
const TitledNotification = ({ content }) => (
    <div style={{ maxWidth: "250px" }}>
        <b>{content.title}</b>
        <div className="text-wrap">
            <p>{content.body}</p>
            {content.traceId ? <p><label>TraceId:</label> {content.traceId}</p> : null}
        </div>
    </div>
)

export default TitledNotification;
