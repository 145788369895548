import { combineReducers } from "redux";
import summary from "./summaryReducer";
import configuration from "./configurationReducer";
import bulkStatus from "./bulkStatusReducer";
import identity from "./identityReducer"
import template from "./templateReducer"
import templateTypes from "./templateTypesReducer"
import serviceManager from "./serviceManagerReducer"
import detailBatchFile from "./detailBatchFileReducer"
import batchFileProcessed from "./batchFileProcessedReducer"
import bulkSearch from "./bulkSearchReducer";
import apiCallsInProgress from "./apiStatusReducer";
import rolesUser from "./rolesUserReducer";

const rootReducer = combineReducers({
    summary,
    configuration,
    bulkSearch,
    bulkStatus,
    template,
    templateTypes,
    identity,
    serviceManager,
    detailBatchFile,
    batchFileProcessed,
    rolesUser,
    apiCallsInProgress
});

export default rootReducer;
