import * as types from "./actionTypes";
import * as configurationAPI from "../../api/configurationAPI";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadConfigurationsSuccess(configuration) {
    return { type: types.LOAD_CONFIGURATIONS_SUCCESS, configuration };
}

export function updateConfigurationSuccess(configuration) {
    return { type: types.UPDATE_CONFIGURATIONS_SUCCESS, configuration };
}

export function loadConfigurations() {
    return function (dispatch) {
        dispatch(beginApiCall());
        return configurationAPI.getConfiguration()
            .then(configuration => {
                dispatch(loadConfigurationsSuccess(configuration));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}

export function saveConfiguration(configuration) {
    return function (dispatch, getState) {
        return configurationAPI.updateConfiguration(configuration)
            .then(saveConfiguration => {
                dispatch(updateConfigurationSuccess(saveConfiguration));
                return saveConfiguration;
            })
            .catch(error => {
                throw error;
            });
    };
}

