import * as types from "./actionTypes";
import * as bulkAPI from "../../api/bulkAPI";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadSummarySuccess(batchFileSummary) {
    return { type: types.LOAD_SUMMARY_SUCCESS, batchFileSummary };
}

export function loadBatchFileStatusSuccess(batchFileStatus) {
    return { type: types.LOAD_STATUS_SUCCESS, batchFileStatus };
}

export function loadBatchFileSearchSuccess(batchFiles) {
    return { type: types.LOAD_BULK_IMPORTER_SEARCH_SUCCESS, batchFiles };
}

export function loadBatchFileAdvancedSearchSuccess(batchFiles) {
    return { type: types.LOAD_BULK_IMPORTER_ADVANCED_SEARCH_SUCCESS, batchFiles };
}

export function getBatchFileDetailSuccess(batchFile) {
    return { type: types.LOAD_BATCH_FILE_DETAIL_SUCCESS, batchFile };
}

export function getBatchFileProcessedSuccess(batchFileProcessed) {
    return { type: types.LOAD_BATCH_FILE_PROCESSED_SUCCESS, batchFileProcessed };
}

export function loadRolesSuccess(roles) {
    return { type: types.LOAD_ROLES_SUCCESS, roles };
}

export function loadRolesByUserIdSuccess(rolesUser) {
    return { type: types.LOAD_ROLES_USER_SUCCESS, rolesUser };
}

export function loadSummary() {
    return function (dispatch) {
        dispatch(beginApiCall());
        return bulkAPI.getSummary()
            .then(batchFileSummary => {
                dispatch(loadSummarySuccess(batchFileSummary));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}

export function loadBatchFileStatus() {
    return function (dispatch) {
        dispatch(beginApiCall());
        return bulkAPI.getBatchFileStatus()
            .then(batchFileStatus => {
                dispatch(loadBatchFileStatusSuccess(batchFileStatus));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}

export function loadBatchFileSearch(params) {
    return function (dispatch) {
        dispatch(beginApiCall());
        return bulkAPI.getBulkImporterSearch(params)
            .then(batchFiles => {
                dispatch(loadBatchFileSearchSuccess(batchFiles));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}

export function loadBatchFileAdvancedSearch(params) {
    return function (dispatch) {
        dispatch(beginApiCall());
        return bulkAPI.postBulkImporterAdvancedSearch(params)
            .then(batchFiles => {
                dispatch(loadBatchFileAdvancedSearchSuccess(batchFiles));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}

export function getBatchFileDetail(batchFileId) {
    return function (dispatch) {
        dispatch(beginApiCall());
        return bulkAPI.getBatchFileId(batchFileId)
            .then(batchFile => {
                dispatch(getBatchFileDetailSuccess(batchFile));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}

export function getBatchFileProcessed(batchFileId, params) {
    return function (dispatch) {
        dispatch(beginApiCall());
        return bulkAPI.getBatchFileProcessed(batchFileId, params)
            .then(batchFileProcessed => {
                dispatch(getBatchFileProcessedSuccess(batchFileProcessed));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}

export function getRoles() {
    return function (dispatch) {
        dispatch(beginApiCall());
        return bulkAPI.getRoles()
            .then(roles => {
                dispatch(loadRolesSuccess(roles));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}

export function getRolesByUserId(subjectId) {
    return function (dispatch) {
        dispatch(beginApiCall());
        return bulkAPI.getRolesByUserId(subjectId)
            .then(rolesUser => {
                dispatch(loadRolesByUserIdSuccess(rolesUser));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}