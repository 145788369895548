import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function identityReducer(state = initialState.rolesUser, action) {
    switch (action.type) {
        case types.LOAD_ROLES_USER_SUCCESS:
            return action.rolesUser;
        default:
            return state;
    }
}
