import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Tab extends Component {
    static propTypes = {
        activeTab: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    onClick = () => {
        const { eventKey, onClick } = this.props;
        onClick(eventKey);
    }

    render() {
        const {
            onClick,
            props: {
                activeTab,
                eventKey,
            },
        } = this;

        let classLiName = 'nav-item';

        let classAName = 'nav-link'

        if (activeTab === eventKey) {
            classAName += '  active show';
        }

        return (
            <li
                className={classLiName}
                onClick={onClick}
            >
                <a className={classAName} role="tab" data-toggle="tab">{this.props.label}</a>

            </li>
        );
    }
}

export default Tab;