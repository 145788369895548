import * as types from "./actionTypes";
import * as serviceManagerAPI from "../../api/serviceManagerAPI";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadScopesSuccess(scopes) {
    return { type: types.LOAD_SM_SCOPES_SUCCESS, scopes };
}

export function loadScopes() {
    return function (dispatch) {
        dispatch(beginApiCall());
        return serviceManagerAPI
            .getScopes()
            .then(scopes => {
                dispatch(loadScopesSuccess(scopes));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}
