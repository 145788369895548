export class TokenModel {
    /**
     * @param {object} token
     * @param {string} token.access_token 
     * @param {string} token.refresh_token 
     * @param {number} token.expires_at 
     * @param {string} token.token_type 
     * @param {UserModel} token.profile 
     * @param {string} idToken
     */
    constructor({ access_token, refresh_token, expires_at, token_type, profile }, idToken) {
        this.access_token = access_token;
        this.refresh_token = refresh_token;
        this.expires_at = expires_at;
        this.token_type = token_type;
        this.profile = profile;
        this.id_token = idToken;
    }
}

export class UserModel {
    /**
     * 
     * @param {object} user
     * @param {string} user.sub
     * @param {string} user.name
     */
    constructor({ sub, name }) {
        this.sub = sub;
        this.name = name;
    }
}