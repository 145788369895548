import * as types from "./actionTypes";
import * as templateAPI from "../../api/templateAPI";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadTemplateTypesSuccess(templateTypes) {
    return { type: types.LOAD_TEMPLATE_TYPES_SUCCESS, templateTypes };
}

export function loadTemplatesSuccess(templates) {
    return { type: types.LOAD_TEMPLATES_SUCCESS, templates };
}

export function uploadedTemplateSuccess(template) {
    return { type: types.UPLOADED_TEMPLATES_SUCCESS, template };
}


export function loadTemplateTypes() {
    return function (dispatch) {
        dispatch(beginApiCall());
        return templateAPI
            .getTemplateTypes()
            .then(templateTypes => {
                dispatch(loadTemplateTypesSuccess(templateTypes));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}

export function loadTemplates() {
    return function (dispatch) {
        dispatch(beginApiCall());
        return templateAPI
            .getTemplateSearch()
            .then(templates => {
                dispatch(loadTemplatesSuccess(templates));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}

export function uploadedTemplates(template) {
    return function (dispatch) {
        dispatch(beginApiCall());
        return templateAPI
            .postTemplate(template)
            .then(saveTemplate => {
                dispatch(uploadedTemplateSuccess(saveTemplate));
                return saveTemplate;
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            });
    };
}
