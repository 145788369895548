import React, { useEffect } from "react";
import { connect } from "react-redux";
import { loadBatchFileStatus } from "../../redux/actions/bulkActions";
import { loadTemplateTypes } from "../../redux/actions/templateActions";
import { loadIdentityUsers } from "../../redux/actions/identityActions";
import { loadScopes } from "../../redux/actions/serviceManagerActions";
import { Collapse, CardBody, Card } from "reactstrap";
import { faSearch, faTrash, faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputField, SelectInput, TreeView } from "../FormComponents/FormFields";
import { CircleLoader } from "../PageFillers/PageLoaders";
import * as functionUtils from "../../functions/functionUtils"
import { useTranslation } from 'react-i18next';

function SearchBatchFile({
    templateTypes,
    addedBy,
    scopes,
    status,
    loadBatchFileStatus,
    loadTemplateTypes,
    loadIdentityUsers,
    loadScopes,
    ...props
}) {
    const { t } = useTranslation();
    const loading = (templateTypes.length === 0 && status.length === 0 && addedBy.length === 0 && scopes.length === 0);

    useEffect(() => {
        handleFetchStatusResponse();
        handleFetchTemplateTypesResponse();
        handleFetchSearchUserResponse();
        handleFetchScopesResponse();
    }, []);

    const handleFetchTemplateTypesResponse = () => {
        if (templateTypes.length === 0)
            loadTemplateTypes();
    };

    const handleFetchStatusResponse = () => {
        if (status.length === 0)
            loadBatchFileStatus();
    };

    const handleFetchSearchUserResponse = () => {
        if (addedBy.length === 0)
            loadIdentityUsers(t);
    };

    const handleFetchScopesResponse = () => {
        if (scopes.length === 0)
            loadScopes();
    };

    return (
        <>
            {loading ? (
                <CircleLoader />
            ) : (
                    <>
                        <article className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-md-3">
                                    <InputField
                                        name={"query"}
                                        disabled={props.isOpenAdvanced}
                                        placeholder={t("inputField.insertSearchTextHere")}
                                        value={props.simpleSearch.query}
                                        handleChange={props.handleSimpleSearchChange}
                                        onKeyPress={props.onKeyDownHandler}
                                    />
                                </div>
                                <div className="col-md-6 margin-top">
                                    <button className="btn btn-primary" onClick={props.getBatchFileSearch} disabled={props.isOpenAdvanced}>
                                        <FontAwesomeIcon icon={faSearch} />{` ${t("button.search")}`}
                                    </button>
                                    <button className="btn btn-link" onClick={props.toggle} >
                                        {t("button.advancedSearch")}
                                </button>
                                </div>
                            </div>
                            <Collapse isOpen={props.isOpenAdvanced}>
                                <Card>
                                    <CardBody>
                                        <div className="advancedSearch">
                                            <div className="row">
                                                <fieldset className="col-md-3 m-0 form-group">
                                                    <InputField
                                                        placeholder={" "}
                                                        label={t("inputField.fileName")}
                                                        name={"BatchFileName"}
                                                        value={props.searchValues.BatchFileName}
                                                        handleChange={props.handleSearchValuesChange}
                                                        onKeyPress={props.onKeyDownHandler}
                                                    />
                                                </fieldset>
                                                <fieldset className="col-md-3 m-0 form-group">
                                                    <InputField
                                                        type={"date"}
                                                        placeholder={" "}
                                                        name={"StartDate"}
                                                        label={t("inputField.startDate")}
                                                        value={props.searchValues.StartDate}
                                                        handleChange={props.handleSearchValuesChange}
                                                        error={props.errors.startDate}
                                                    />
                                                </fieldset>
                                                <fieldset className="col-md-3 m-0 form-group">
                                                    <InputField
                                                        type={"date"}
                                                        placeholder={" "}
                                                        name={"EndDate"}
                                                        label={t("inputField.endDate")}
                                                        value={props.searchValues.EndDate}
                                                        handleChange={props.handleSearchValuesChange}
                                                    />
                                                </fieldset>
                                                {addedBy.length > 0 ?
                                                    <fieldset className="col-md-3 m-0 form-group">
                                                        <SelectInput
                                                            label={t("inputField.addedBy")}
                                                            name={"AddedBy"}
                                                            onChange={props.handleSearchValuesChange}
                                                            options={addedBy}
                                                            selected={props.searchValues.AddedBy}
                                                        />
                                                    </fieldset>
                                                : null}
                                            </div>
                                            <div className="row">
                                                <fieldset className="col-md-3 m-0 form-group">
                                                    <SelectInput
                                                        label={t("inputField.typeFileUploaded")}
                                                        name={"templateTypeId"}
                                                        onChange={props.handleAdvandeSearchChange}
                                                        options={functionUtils.translateSelectInput(templateTypes, t)}
                                                        selected={props.advandeSearchInfo.templateTypeId}
                                                    />
                                                </fieldset>
                                                <fieldset className="col-md-3 m-0 form-group">
                                                    <SelectInput
                                                        label={t("inputField.status")}
                                                        name={"BatchFileStateId"}
                                                        onChange={props.handleSearchValuesChange}
                                                        options={functionUtils.translateSelectInput(status, t)}
                                                        selected={props.searchValues.BatchFileStateId}
                                                    />
                                                </fieldset>
                                            </div>
                                            <div className="row">
                                                <fieldset className="col-md-6 m-0 form-group">
                                                    <TreeView
                                                        uncheckAllTreeView={props.uncheckAllTreeView}
                                                        label={t("inputField.scopes")}
                                                        name={"checkId"}
                                                        nodes={scopes}
                                                        checked={props.check.checkId}
                                                        onCheck={props.handleScopeChecked}
                                                        checkNodesRecursive={false}
                                                    />
                                                </fieldset>
                                            </div>
                                            <div className="row">
                                                <fieldset className="col-md-12 m-0 p-0 form-group">
                                                    <div className="md-form m-0 pull-right clearfix">
                                                        <button className="btn btn-primary" onClick={props.cleanFilters}>
                                                            <FontAwesomeIcon icon={faTrash} />{` ${t("button.clearFilters")}`}
                                                        </button>
                                                        <button className="btn btn-primary" onClick={props.postBatchFileAdvancedSearch}>
                                                            <FontAwesomeIcon icon={faSearchPlus} />{` ${t("button.advancedSearch")}`}
                                                        </button>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </article>
                    </>
                )
            }
        </>
    );
}

function mapStateToProps(state) {
    const templateTypes = state.templateTypes.length !== 0 ? functionUtils.parseSelectOptions(state.templateTypes) : [];
    const addedBy = state.identity !== undefined && state.identity.results !== undefined? functionUtils.parseSelectIdentityUserOptions(state.identity.results) : [];
    const scopes = state.serviceManager.length !== 0 ? functionUtils.parseCheckTreeScopes(state.serviceManager) : [];
    const status = state.bulkStatus.length !== 0 ? functionUtils.parseSelectOptions(state.bulkStatus) : [];

    return {
        status,
        templateTypes,
        addedBy,
        scopes,
        loading: state.apiCallsInProgress > 0
    };
}

const mapDispatchToProps = {
    loadBatchFileStatus,
    loadTemplateTypes,
    loadIdentityUsers,
    loadScopes
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBatchFile);
