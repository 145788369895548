import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import * as functionUtils from "../../functions/functionUtils"

const allowedLanguages = ['en-GB', 'es-ES'];
const defaultLng = 'en-GB';
let lng = defaultLng;
let cultureCookies = functionUtils.getCookie("BulkImporterCulture");
const userLang = cultureCookies || navigator.language || navigator.userLanguage;

if (userLang && allowedLanguages.indexOf(functionUtils.getLanguageText(userLang)) > -1) {
    lng = functionUtils.getLanguageText(userLang);
}

functionUtils.setCookie("BulkImporterCulture", lng, 365);

i18n
    .use(XHR)
    .use(initReactI18next)
    .init({
        lng,
        load: allowedLanguages,
        preload: allowedLanguages,
        debug: true,
        keySeparator: true,
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: '/translations/{{lng}}.json',
            allowMultiLoading: true
        },
        react: {
            wait: true,
            useSuspense: false
        }
    });

export default i18n;