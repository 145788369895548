import React, { useState, useEffect, useContext } from 'react';
import { connect } from "react-redux";
import { getBatchFileDetail, getBatchFileProcessed } from "../../redux/actions/bulkActions";
import PropTypes from "prop-types";
import { InputField, ReactTable } from "../FormComponents/FormFields";
import { batchFileProccessState, batchFileState } from '../../constants/batchFileConstants'
import { faPlayCircle, faStopCircle, faPauseCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddReasonModal } from '../Modals/AddReasonModal'
import { ContinueOrCancelModal } from '../Modals/ContinueOrCancelModal'
import { DetailProcessModal } from '../Modals/DetailProcessModal'
import * as functionUtils from "../../functions/functionUtils"
import { getBatchFileId, getBatchFileIncorrectsProcess, putBatchFileStatus } from '../../api/bulkAPI';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { CircleLoader } from "../PageFillers/PageLoaders";
import 'react-circular-progressbar/dist/styles.css';
import { AuthContext } from '../Auth/AuthContext';
import Pagination from "react-js-pagination";
import { SelectInput } from "../FormComponents/FormFields";
import { TitleComponent } from '../common/Title';
import { role } from '../../constants/roleConstants'
import { useTranslation } from 'react-i18next';

function DetailBatchFile({
    batchFile,
    batchFileProcessed,
    rowsWithErrorsTotal,
    getBatchFileDetail,
    getBatchFileProcessed,
    ...props
}) {
    const { t } = useTranslation();
    const authService = useContext(AuthContext);
    const [playModalIsOpen, setPlayModalIsOpen] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [detailProcessModalIsOpen, setDetailProcessModalIsOpen] = useState(false);
    const [detailProcess, setDetailProcess] = useState([]);
    const [batchFileNote, setBatchFileNote] = useState({ ...props.batchFileNote });
    const [userId, setUserId] = useState(null);
    const [recordNumber, setRecordNumber] = useState("");
    const [putBody, setPutBody] = useState({});
    const [loadingModal, setloadingModal] = useState(false);
    const [pageSize, setPageSize] = useState(5);
    const [activePage, setActivePage] = useState(1);
    const [dataModal, setdataModal] = useState({
        titleModal: null,
        messageModal: null
    });
    const [simpleSearch] = useState({
        query: "",
        sortBy: "RecordNumber",
        sortDescending: false,
        page: 1,
        pageSize
    });

    const total = batchFileProcessed.total;
    const hiddenPagination = batchFileProcessed.results === undefined || batchFileProcessed.results.length === 0;
    const from = ((batchFileProcessed.page - 1) * batchFileProcessed.pageSize) + 1;
    const to = from + (batchFileProcessed.results == null ? 0 : (batchFileProcessed.results.length - 1));
    const totalNumberPages = Math.ceil(total / pageSize);
    const [readOnlyPermission, setReadOnlyPermission] = useState(false);

    useEffect(() => {
        functionUtils.hasPermissionUser(role.READ_ONLY, authService.getUserId(), res => setReadOnlyPermission(res));
        setUserId(authService.getUserId());
        handleFetchBatchFileResponse();
    }, []);

    const loading = (batchFile.id === undefined || batchFileProcessed.results === undefined);

    const handleFetchBatchFileResponse = () => {
        const batchFileId = props.match.params.batchFileId;
        getBatchFileDetail(batchFileId);
        handleFetchBatchFileProcessedResponse();

    };

    function handleFetchBatchFileProcessedResponse() {
        const batchFileId = props.match.params.batchFileId;
        getBatchFileProcessed(batchFileId, simpleSearch);
    }

    const handleNotesResponse = (notes, type) => {
        setloadingModal(false);
        return setPutBody(prev => ({
            ...prev,
            noteId: notes !== undefined ? notes.noteId : null,
            noteContent: notes !== undefined ? notes.noteContent : null,
            batchFileStateId: type === "stop" ? batchFileState.STOPPED : batchFileState.PAUSED,
            createdBy: userId
        }));

    };

    function toggleModal(type) {
        if (!modalIsOpen) {
            if (type === "stop") {
                setdataModal({
                    titleModal: t("title.areYouSureStop"),
                    messageModal: t("message.addInformationToStop")
                })
            }
            else {
                setdataModal({
                    titleModal: t("title.areYouSurePause"),
                    messageModal: t("message.addInformationToPause")
                })
            }
            setloadingModal(true);
            getBatchFileId(props.match.params.batchFileId)
                .then(_item => { handleNotesResponse(functionUtils.getfirstArray(_item.notes), type) })
                .catch(error => functionUtils.handleError(error));
        }
        setModalIsOpen(!modalIsOpen);
    };


    const togglePlayModal = () => {
        if (!playModalIsOpen) {
            setPutBody({
                batchFileStateId: batchFileState.RESUME_PROCCESSING,
                createdBy: userId,
                noteId: null,
                noteContent: null
            });
        }
        setPlayModalIsOpen(!playModalIsOpen);
    };

    const toggleDetailProcessModal = (proccessId, recordNumber) => {
        if (!detailProcessModalIsOpen) {
            const batchFileId = props.match.params.batchFileId;
            setRecordNumber(recordNumber);
            getBatchFileIncorrectsProcess(batchFileId, proccessId).then(data => setDetailProcess(data));
        }
        setDetailProcessModalIsOpen(!detailProcessModalIsOpen);
    };

    function handleChange(event, nameOfComponent) {
        const { name, value } = event.target;
        setPutBody(prev => ({
            ...prev, [name]: value,
        }));
    };

    function savePlay(event) {
        const batchFileId = props.match.params.batchFileId;
        putBody.batchFileStateId = batchFileState.RESUME_PROCCESSING;
        putBatchFileStatus(batchFileId, putBody)
            .then(res => handleSaveSuccessfull(res))
            .catch(error => functionUtils.handleError(error));

        setPlayModalIsOpen(!playModalIsOpen);
    };

    function saveReason(event) {
        const batchFileId = props.match.params.batchFileId;

        putBatchFileStatus(batchFileId, putBody)
            .then(res => handleSaveSuccessfull(res))
            .catch(error => functionUtils.handleError(error));

        setModalIsOpen(!modalIsOpen);
    };

    const handleSaveSuccessfull = (res) => {
        if (res) {
            functionUtils.handleSuccess(t("message.saveReasonSuccessfull"))
            handleFetchBatchFileResponse();
        }
    };

    const columnsDetailModal = React.useMemo(
        () => [
            {
                Header: 'Name',
                columns: [
                    {
                        Header: 'Column Name',
                        accessor: 'columnName',
                    },
                    {
                        Header: 'Summary',
                        accessor: 'summary',
                    },
                    {
                        Header: 'Status',
                        accessor: 'stateName',
                    }
                ],
            }
        ],
        []
    )

    function handlePageSizeChange(event) {
        const value = event.value;
        setActivePage(1);
        setPageSize(Number(value));
        simpleSearch.page = 1;
        simpleSearch.pageSize = Number(value);
        handleFetchBatchFileProcessedResponse();
    };

    const handleGoToPageChange = event => {
        const totalNumberPages = Math.ceil(total / pageSize);
        const page = event.target.value > totalNumberPages ? totalNumberPages : Number(event.target.value);
        setActivePage(page === 0 ? 1 : page);
        simpleSearch.page = page === 0 ? 1 : page;
        handleFetchBatchFileProcessedResponse();

    }

    const handlePageChange = page => {
        setActivePage(Number(page));
        simpleSearch.page = Number(page);
        handleFetchBatchFileProcessedResponse();
    }


    const options = [
        { value: 5, label: t("selectInput.5Rows") },
        { value: 10, label: t("selectInput.10Rows") },
        { value: 25, label: t("selectInput.25Rows") },
        { value: 50, label: t("selectInput.50Rows") },
        { value: 100, label: t("selectInput.100Rows") }
    ];

    return (
        <div>
            {loading ? (
                <CircleLoader />
            ) : (
                    <>
                        <TitleComponent title={(t("header.batchFileDetail"))} />
                        <h2>{batchFile.name}{` - ${(t("header.batchFileDetail"))}`}</h2>

                        <fieldset className="scheduler-border border rounded p-3 mt-2">
                            <legend className="scheduler-border subsection">{t("legend.file")}</legend>
                            <div className="row">
                                <fieldset className="col-md-3 m-0 form-group">
                                    <InputField
                                        placeholder={" "}
                                        label={t("inputField.fileName")}
                                        value={batchFile.name}
                                        disabled={true}
                                    />
                                </fieldset>
                                <fieldset className="col-md-3 m-0 form-group">
                                    <InputField
                                        placeholder={" "}
                                        label={t("inputField.addedOn")}
                                        value={batchFile.uploadedOn ? functionUtils.formatDateTime(batchFile.uploadedOn) : ''}
                                        disabled={true}
                                    />
                                </fieldset>
                                <fieldset className="col-md-3 m-0 form-group">
                                    <InputField
                                        placeholder={" "}
                                        label={t("inputField.addedBy")}
                                        value={batchFile.addedByName}
                                        disabled={true}
                                    />
                                </fieldset>
                                <fieldset className="col-md-3 m-0 form-group">
                                    <InputField
                                        placeholder={" "}
                                        label={t("inputField.status")}
                                        value={batchFile.stateName}
                                        disabled={true}
                                    />
                                </fieldset>
                            </div>
                            <div className="row">
                                <fieldset className="col-md-3 m-0 form-group">
                                    <InputField
                                        placeholder={" "}
                                        label={t("inputField.typeFile")}
                                        value={batchFile.templateTypeName}
                                        disabled={true}
                                    />
                                </fieldset>
                                <fieldset className="col-md-3 m-0 form-group">
                                    <InputField
                                        placeholder={" "}
                                        label={t("inputField.scopes")}
                                        value={batchFile.scopeName}
                                        disabled={true}
                                    />
                                </fieldset>
                                <fieldset className="col-md-3 m-0 form-group">
                                    <InputField
                                        placeholder={" "}
                                        label={t("inputField.TimesPaused")}
                                        value={batchFile.timePaused}
                                        disabled={true}
                                    />
                                </fieldset>
                            </div>
                            <div className="row">
                                <fieldset className="col-md-12 m-0 form-group">
                                    <textarea className="form-control z-depth-1"
                                        readOnly
                                        rows="5"
                                        value={props.batchFileNote !== undefined ? props.batchFileNote.noteContent : ""}>
                                    </textarea>
                                </fieldset>
                            </div>
                            {!readOnlyPermission ?
                                <div className="row">
                                    <fieldset className="col-md-12 m-0 form-group">
                                        <div className="md-form m-0 pull-right clearfix">
                                            <div className="tooltip-wrapper tooltip-wrapper-without-margen" data-toggle="tooltip" data-placement="top" title={t("inputField.play")}>
                                                <button className="ico-color-green btn btn-floating-custom-icon" disabled={batchFile.stateId.toUpperCase() !== batchFileState.PAUSED}
                                                    onClick={e => togglePlayModal()}>
                                                    <FontAwesomeIcon icon={faPlayCircle} />
                                                </button>
                                            </div>
                                            <div className="tooltip-wrapper tooltip-wrapper-without-margen" data-toggle="tooltip" data-placement="top" title={t("inputField.pause")}>
                                                <button className="ico-color-gold btn btn-floating-custom-icon" disabled={(batchFile.stateId.toUpperCase() !== batchFileState.IN_PROGRESS) && (batchFile.stateId.toUpperCase() !== batchFileState.RESUME_PROCCESSING)}
                                                    onClick={e => toggleModal("pause")}>
                                                    <FontAwesomeIcon icon={faPauseCircle} />
                                                </button>
                                            </div>
                                            <div className="tooltip-wrapper tooltip-wrapper-without-margen" data-toggle="tooltip" data-placement="top" title={t("inputField.stop")}>
                                                <button className="ico-color-red btn btn-floating-custom-icon" disabled={(batchFile.stateId.toUpperCase() !== batchFileState.IN_PROGRESS) && (batchFile.stateId.toUpperCase() !== batchFileState.RESUME_PROCCESSING) && (batchFile.stateId.toUpperCase() !== batchFileState.THREAD_WITH_ERRORS)}
                                                    onClick={e => toggleModal("stop")}>
                                                    <FontAwesomeIcon icon={faStopCircle} />
                                                </button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                : null}
                        </fieldset>

                        <fieldset className="scheduler-border border rounded p-3 mt-2">
                            <legend className="scheduler-border subsection">{t("legend.detail")}</legend>
                            <div className="row">
                                <fieldset className="scheduler-border border rounded col-md-2 m-0 form-group">
                                    <legend className="scheduler-border subsection">{t("legend.rowsWithErrors")}</legend>
                                    <div className="fa-6x mt-15px">
                                        <div className="section-sub-graphic" data-toggle="tooltip" title={t("legend.rowsWithErrors")}>
                                            <CircularProgressbar className="home-circularProgressbar"
                                                value={rowsWithErrorsTotal}
                                                text={`${rowsWithErrorsTotal}%`}
                                                background
                                                backgroundPadding={6}
                                                styles={buildStyles({
                                                    backgroundColor: "#3e98c7",
                                                    textColor: "#fff",
                                                    pathColor: "#fff",
                                                    trailColor: "transparent"
                                                })}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className="col-md-10 m-0 form-group">
                                    <div className="panel panel-default">
                                        <div className="panel-body">
                                            <div className="dataTables_length width-Select-rows" hidden={hiddenPagination}>
                                                <SelectInput
                                                    name={"pageSize"}
                                                    defaultOption={t("inputField.selectTemplateType")}
                                                    onChange={handlePageSizeChange}
                                                    options={options}
                                                    selected={pageSize}
                                                />
                                            </div>
                                            <div>
                                                <div className="table-responsive">
                                                    <table className='table table-bordered table-striped' aria-labelledby="tabelLabel">
                                                        <thead className="#bbdefb blue lighten-3 headerTableText breakWhiteSpace">
                                                            <tr>
                                                                <th>{t("dataTable.fileDetail.row")}</th>
                                                                <th>{t("dataTable.fileDetail.summary")}</th>
                                                                <th>{t("dataTable.fileDetail.status")}</th>
                                                                {/* <th>{t("dataTable.action")}</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {batchFileProcessed.results.length === 0 ? (
                                                                <tr><td colSpan="4" className="dataTables_empty">{t("dataTable.empty")}</td></tr>
                                                        ) :
                                                            (batchFileProcessed.results.map(processed =>
                                                                <tr key={processed.proccessId}>
                                                                    <td>{processed.recordNumber}</td>
                                                                    <td>{processed.summary.includes(".") ? processed.summary.replace(processed.summary.split(".")[0], t(processed.summary.split(".")[0])) : processed.summary}</td>
                                                                    <td>{t(processed.stateName)}</td>
                                                                    {/*<td>
                                                                        <div className="tooltip-wrapper tooltip-wrapper-without-margen">
                                                                            <div className="tooltip-wrapper tooltip-wrapper-without-margen" data-toggle="tooltip" data-placement="top" title={t("inputField.detailProcess")}>
                                                                                <button className="ico-color-blue btn btn-floating-custom" disabled={processed.stateId.toUpperCase() !== batchFileProccessState.UNSUCCESSFUL}
                                                                                    onClick={e => toggleDetailProcessModal(processed.proccessId, processed.recordNumber)}>
                                                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </td>*/}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div hidden={hiddenPagination}>
                                                    {`${t("dataTable.footer.showing")} `} {from} {` ${t("dataTable.footer.to")} `} {to} {` ${t("dataTable.footer.of")} `} {total} {` ${t("dataTable.footer.entries")}`}
                                                    <div className="pagination pull-right">
                                                        <div className="pagination pull-right">
                                                            <div className="row">
                                                                <label>{`${t("dataTable.pagination.page")} `}</label>
                                                                <input
                                                                    className="form-control width-Select-rows"
                                                                    type={"number"}
                                                                    name={"goToPage"}
                                                                    value={activePage}
                                                                    onChange={handleGoToPageChange}
                                                                    min={1}
                                                                    max={totalNumberPages}
                                                                />
                                                                <Pagination
                                                                    hideNavigation
                                                                    firstPageText={t("dataTable.pagination.first")}
                                                                    lastPageText={t("dataTable.pagination.last")}
                                                                    activePage={activePage}
                                                                    itemsCountPerPage={pageSize}
                                                                    totalItemsCount={total}
                                                                    pageRangeDisplayed={pageSize}
                                                                    onChange={handlePageChange}
                                                                    pageRangeDisplayed={5}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </fieldset>

                        <ContinueOrCancelModal
                            toggle={togglePlayModal}
                            isOpen={playModalIsOpen}
                            title={t("title.areYouSure")}
                            message={t("message.areYouSureYouWantToPlay")}
                            onClick={savePlay}
                        />
                        <AddReasonModal
                            toggle={toggleModal}
                            isOpen={modalIsOpen}
                            title={dataModal.titleModal}
                            message={dataModal.messageModal}
                            value={putBody.noteContent != null ? putBody.noteContent : ""}
                            name={"noteContent"}
                            label={t("button.save")}
                            handleChange={handleChange}
                            onClick={saveReason}
                            loading={loadingModal}
                        />
                        <DetailProcessModal
                            size={"lg"}
                            toggle={toggleDetailProcessModal}
                            isOpen={detailProcessModalIsOpen}
                            value={detailProcess}
                            title={recordNumber}
                            columns={columnsDetailModal}
                        />
                    </>
                )}
        </div>
    );
}

DetailBatchFile.propTypes = {
    getBatchFileDetail: PropTypes.func.isRequired
};


function mapStateToProps(state) {
    const rowsWithErrors = state.batchFileProcessed.rowsWithErrors;
    const rowsWithErrorsTotal = rowsWithErrors > 0 ? Math.ceil(((rowsWithErrors / state.batchFileProcessed.total) * 100)) : 0;

    const batchFileNote = state.detailBatchFile.notes !== undefined ? functionUtils.getfirstArray(state.detailBatchFile.notes) : {};

    return {
        batchFile: state.detailBatchFile,
        batchFileProcessed: state.batchFileProcessed,
        batchFileNote,
        rowsWithErrorsTotal
    };
}

const mapDispatchToProps = {
    getBatchFileDetail,
    getBatchFileProcessed
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailBatchFile);