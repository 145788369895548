import React from "react";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import { ReactTable } from "../FormComponents/FormFields";
import { useTranslation } from 'react-i18next';

export const DetailProcessModal = props => {
    const isOpen = props.isOpen;
    const { t } = useTranslation();

    return (
        <>
            <Modal size={props.size || ""} isOpen={isOpen} toggle={props.toggle} backdrop="static">
                <ModalHeader className="modal-header white-text">{` ${t("inputField.detailProcess")} - `}{props.title}</ModalHeader>
                <ModalBody style={{ textAlign: "left" }}>
                    <Row>
                        <Col>
                            <ReactTable columns={props.columns} data={props.value} />
                        </Col>
                    </Row>
                    <div className="modal-footer justify-content-right">
                        <button onClick={props.toggle} className="btn btn-outline-primary">{t("button.close")}</button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};
