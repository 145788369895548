import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function configurationReducer(state = initialState.configuration, action) {
    switch (action.type) {
        case types.LOAD_CONFIGURATIONS_SUCCESS:
            return action.configuration;
        case types.UPDATE_CONFIGURATIONS_SUCCESS:
            return state;
        default:
            return state;
    }
}
