import { handleError } from "../functions/functionUtils"
import AuthService from "../components/Auth/AuthService";

const authService = new AuthService();

/**
 * @typedef {import('../functions/functionUtils').errorCallback} errorCallback
 */

/**
 * @param {string} url
 * @param {any} params
 * @param {string} method
 * @param {errorCallback} errorCallback
 */
async function request(url, params, method = 'GET', isPublic, errorCallback) {
    const token = isPublic ? null : await authService.getAccessToken();

    if (!isPublic && token === null) {
        alert('Session timeout');
        window.location.reload(true);
    }

    let options = {
        method,
        headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json'
        })
    };

    if (token) {
        options.headers.append('Authorization', `Bearer ${token}`);
    }

    if (params) {
        if (method === 'GET') {
            url += '?' + objectToQueryString(params);
        } else {
            options.body = JSON.stringify(params);
        }
    }

    return fetch(url, options)
        .then(async res => {
            const body = await res.text();
            if (res.ok) return JSON.parse(body);
            else {
                let traceId = undefined;
                if(body.includes("TraceId")) traceId = JSON.parse(body).TraceId;
                throw { title: `ERR: (${res.status}) ${res.statusText}`, body: `${method} ${url}`, traceId: traceId, code: res.status };
            }
        }, reason => { throw reason })
        .catch(msg => handleError(msg, errorCallback));
}

function objectToQueryString(obj) {
    return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}

/**
 *
 * @param {string} url
 * @param {any} [params]
 * @param {boolean} [isPublic=false]
 * @param {errorCallback} [errorCallback]
 */
function get(url, params, isPublic = false, errorCallback) {
    return request(url, params, 'GET', isPublic, errorCallback);
}

/**
 *
 * @param {string} url
 * @param {any} [params]
 * @param {boolean} [isPublic=false]
 * @param {errorCallback} [errorCallback]
 */
function post(url, params, isPublic = false, errorCallback) {
    return request(url, params, 'POST', isPublic, errorCallback);
}

/**
 *
 * @param {string} url
 * @param {any} params
 * @param {boolean} [isPublic=false]
 * @param {errorCallback} [errorCallback]
 */
function put(url, params, isPublic = false, errorCallback) {
    return request(url, params, 'PUT', isPublic, errorCallback);
}

/**
 *
 * @param {string} url
 * @param {any} [params]
 * @param {boolean} [isPublic=false]
 * @param {errorCallback} [errorCallback]
 */
function remove(url, params, isPublic = false, errorCallback) {
    return request(url, params, 'DELETE', isPublic, errorCallback);
}

export default {
    get,
    post,
    put,
    remove
};