import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';


function Footer() {
    const { t } = useTranslation();
    const [version, setVersion] = useState("");

    useEffect(() => {
        getAssemblyVersion();
    }, []);

    const getAssemblyVersion = () => {
        const headers = { 'Content-Type': 'application/json' }
        fetch('assemblyVersion', { headers })
            .then(response => response.text())
            .then(data => setVersion(data));
    }

    return (
        <footer className="page-footer fixed-bottom">
            <div className="footer-copyright bg-primary">
                <Container className="container">
                    <div className="row pt-1 m-0">
                        <div className="col-sm-12 p-0 m-0">
                            <a href="http://www.tunstall.com" target="_blank" rel="noopener noreferrer">
                                <strong>Tunstall</strong>
                            </a> {(new Date().getFullYear())}{`. ${t("footer.allRightsReservedVersion")} `}{version}
                        </div>
                    </div>
                </Container>
            </div>
        </footer>
    );
}

export default Footer;