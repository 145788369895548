import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from './components/Route/PrivateRoute';
import PublicRoute from './components/Route/PublicRoute';
import Home from './components/Home';
import FileManagement from './components/FileManagement/Index';
import DetailBatchFile from './components/FileManagement/DetailBatchFile';
import Configuration from './components/Configuration/Configuration';
import SignIn from './components/Login/SignIn';
import SignOut from './components/Login/SignOut';
import { PageNotFound } from "./components/PageFillers/ErrorPages";
import { PageAccessDenied } from "./components/PageFillers/AccessDenied";
import { toast } from "react-toastify";
import { AuthContext } from './components/Auth/AuthContext';
import AuthService from './components/Auth/AuthService';
import "react-toastify/dist/ReactToastify.css";
import './css/custom.css';
import Login from './components/Login/Login';
import { useTranslation } from 'react-i18next';
import { role } from './constants/roleConstants'
toast.configure();
function App() {
    const [authService] = useState(new AuthService());
    const title = 'react-i18next-translations';

    const { t } = useTranslation();

    const changeLanguage = code => e => {
        localStorage.setItem('language', code);

        window.location.reload(true);
    }
    return (
        <AuthContext.Provider value={authService}>
            <Router>
                <Switch>
                    <PrivateRoute exact path='/' component={Home} />
                    <PrivateRoute exact path='/fileManagement' component={FileManagement} />
                    <PrivateRoute exact path='/fileManagement/:batchFileId' component={DetailBatchFile} />
                    <PrivateRoute path='/configuration' roles={[role.ADMIN]} component={Configuration} />
                    <Route exact path="/signin" component={SignIn} />
                    <Route exact path="/signout" component={SignOut} />
                    <Route exact path="/login" component={Login} />
                    <PublicRoute exact path='/accessDenied/:urlDenied' component={PageAccessDenied} />
                    <PublicRoute component={PageNotFound} />
                </Switch>
            </Router>
        </AuthContext.Provider>
    );
}

export default App;