import React, { useState, useEffect, useContext } from 'react';
import { connect } from "react-redux";
import { loadBatchFileSearch, loadBatchFileAdvancedSearch } from "../../redux/actions/bulkActions";
import BatchFileTable from "./BatchFileTable";
import SearchBatchFile from "./SearchBatchFile";
import { CircleLoader } from "../PageFillers/PageLoaders";
import { AuthContext } from '../Auth/AuthContext';
import * as functionUtils from "../../functions/functionUtils"
import { TitleComponent } from '../common/Title';
import { role } from '../../constants/roleConstants'
import { useTranslation } from 'react-i18next';

function FileManagement({
    batchFiles,
    loadBatchFileSearch,
    loadBatchFileAdvancedSearch,
    total,
    loading
}) {
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const authService = useContext(AuthContext);
    const [userId, setUserId] = useState(null);
    const [check, setCheck] = useState({});
    const [isOpenAdvanced, setIsOpenAdvanced] = useState(false);
    const toggle = () => setIsOpenAdvanced(!isOpenAdvanced);
    const [pageSize, setPageSize] = useState(10);
    const [activePage, setActivePage] = useState(1);
    const [readOnlyPermission, setReadOnlyPermission] = useState(false);

    const [simpleSearch, setSimpleSearch] = useState({
        query: "",
        sortBy: "AddedOn",
        sortDescending: true,
        page: 1,
        pageSize
    });

    const [searchValues, setSearchValues] = useState({
        BatchFileName: "",
        StartDate: "",
        EndDate: "",
        AddedBy: "",
        BatchFileStateId: "",
        ScopeId: "",
    });

    const [advandeSearchInfo, setAdvandeSearchFilers] = useState({
        templateTypeId: null,
        searchValues: {},
        sortBy: "AddedOn",
        sortDescending: true,
        page: 1,
        pageSize
    });

    useEffect(() => {
        functionUtils.hasPermissionUser(role.READ_ONLY, authService.getUserId(), res => setReadOnlyPermission(res));
        batchFiles.results = [];
        setUserId(authService.getUserId());
    }, []);

    function callTypeSearch() {
        if (isOpenAdvanced)
            postBatchFileAdvancedSearch();
        else
            getBatchFileSearch();
    }

    function formIsValid() {
        const { StartDate, EndDate } = searchValues;
        const errors = {};
        if (Date.parse(StartDate) > Date.parse(EndDate))
        {
            errors.startDate = t("warning.startDate")
            functionUtils.handleWarning(t("warning.startDate"));
        };

        setErrors(errors);
        // Form is valid if the errors object still has no properties
        return Object.keys(errors).length === 0;
    }

    function getBatchFileSearch() {
        loadBatchFileSearch(simpleSearch);
    }

    function postBatchFileAdvancedSearch() {
        if (!formIsValid()) return;
        advandeSearchInfo.searchValues = searchValues;
        loadBatchFileAdvancedSearch(advandeSearchInfo);
    }

    function handleSimpleSearchChange(event) {
        const { name, value } = event.target;
        setSimpleSearch(prev => ({
            ...prev, [name]: value,
        }));
    };

    function handleSearchValuesChange(event, nameOfComponent) {
        if (nameOfComponent !== undefined) {
            let value = event.value;
            setSearchValues(prev => ({
                ...prev, [nameOfComponent.name]: value,
            }));
        }
        else {
            const { name, value } = event.target;
            setSearchValues(prev => ({
                ...prev, [name]: value,
            }));
        }
    }

    function handleAdvandeSearchChange(event, nameOfComponent) {
        if (nameOfComponent !== undefined) {
            let value = event.value;
            setAdvandeSearchFilers(prev => ({
                ...prev, [nameOfComponent.name]: value,
            }));
        }
        else {
            const { name, value } = event.target;
            setAdvandeSearchFilers(prev => ({
                ...prev, [name]: value,
            }));
        }
    };

    function handleScopeChecked(event) {
        setCheck.checkId = [];
        setSearchValues.scopeId = "";
        let value = event;
        setCheck(prev => {
            return { ...prev, checkId: value };
        });
        setSearchValues(prev => ({ ...prev, ScopeId: value.join(';') }));
    };

    function cleanFilters() {
        setSearchValues({
            BatchFileName: "",
            StartDate: "",
            EndDate: "",
            AddedBy: "",
            BatchFileStateId: "",
            ScopeId: ""
        });

        setAdvandeSearchFilers({
            templateTypeId: null,
            searchValues: {},
            sortBy: "AddedOn",
            sortDescending: false,
            page: 1,
            pageSize: 10,
        });

        setCheck(prev => ({ ...prev, checkId: [] }));
    }

    function uncheckAllTreeView(event) {
        setCheck(prev => ({ ...prev, checkId: [] }));
        setSearchValues(prev => ({ ...prev, scopeId: "" }));
    };

    function handlePageSizeChange(event) {
        const value = event.value;
        setActivePage(1);
        setPageSize(Number(value));
        simpleSearch.page = 1;
        simpleSearch.pageSize = Number(value);
        advandeSearchInfo.pageSize = Number(value);
        advandeSearchInfo.page = 1;
        callTypeSearch();
    };

    const handleGoToPageChange = event => {
        const totalNumberPages = Math.ceil(total / pageSize);
        const page = event.target.value > totalNumberPages ? totalNumberPages : Number(event.target.value);
        setActivePage(page === 0 ? 1 : page);
        simpleSearch.page = page === 0 ? 1 : page;
        advandeSearchInfo.page = Number(page === 0 ? 1 : page);
        callTypeSearch();

    }

    const handlePageChange = page => {
        setActivePage(Number(page));
        simpleSearch.page = Number(page);
        advandeSearchInfo.page = Number(page);
        callTypeSearch();
    }

    const onKeyDownHandler = target => {
        if (target.charCode === 13) {
            callTypeSearch();
            return false;
        }
    };

    return (
        <>
            {batchFiles ? (
                <div>
                    <TitleComponent title={t("header.searchBatchFiles")} />
                    <h2>{t("header.searchBatchFiles")}</h2>
                    <SearchBatchFile
                        getBatchFileSearch={getBatchFileSearch}
                        postBatchFileAdvancedSearch={postBatchFileAdvancedSearch}
                        handleSimpleSearchChange={handleSimpleSearchChange}
                        handleAdvandeSearchChange={handleAdvandeSearchChange}
                        handleSearchValuesChange={handleSearchValuesChange}
                        handleScopeChecked={handleScopeChecked}
                        simpleSearch={simpleSearch}
                        searchValues={searchValues}
                        advandeSearchInfo={advandeSearchInfo}
                        cleanFilters={cleanFilters}
                        uncheckAllTreeView={uncheckAllTreeView}
                        check={check}
                        isOpenAdvanced={isOpenAdvanced}
                        toggle={toggle}
                        onKeyDownHandler={onKeyDownHandler}
                        errors={errors}
                    />
                    <br />
                    {loading ? (
                        <CircleLoader />
                    ) : (
                            <>
                                <BatchFileTable
                                    activePage={activePage}
                                    pageSize={pageSize}
                                    handlePageSizeChange={handlePageSizeChange}
                                    batchFiles={batchFiles}
                                    userId={userId}
                                    handlePageChange={handlePageChange}
                                    handleGoToPageChange={handleGoToPageChange}
                                    callTypeSearch={callTypeSearch}
                                    readOnlyPermission={readOnlyPermission}
                                />
                            </>
                        )}
                </div>
            ) : (
                    <CircleLoader />
                )}
        </>
    );
}

function mapStateToProps(state) {
    const total = state.bulkSearch.length !== 0 ? state.bulkSearch.total : 0;
    return {
        total,
        batchFiles: state.bulkSearch,
        loading: state.apiCallsInProgress > 0
    };
}

const mapDispatchToProps = {
    loadBatchFileSearch,
    loadBatchFileAdvancedSearch
}

export default connect(mapStateToProps, mapDispatchToProps)(FileManagement);
