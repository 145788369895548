import Fetch from "./fetchUtils"
const rootUrl = 'api/template'

export function getTemplateTypes() {
    return Fetch.get(`${rootUrl}/types`);
}

export function getTemplateSearch() {
    return Fetch.get(`${rootUrl}/search`);
}

export function getTemplateByTemplateId(templateId) {
    return Fetch.get(`${rootUrl}/${templateId}`);
}

export function postTemplate(postBody) {
    return Fetch.post(`${rootUrl}`, postBody);
}
