import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function templateTypesReducer(state = initialState.templateTypes, action) {
    switch (action.type) {
        case types.LOAD_TEMPLATE_TYPES_SUCCESS:
            return action.templateTypes;
        default:
            return state;
    }
}
