import React from "react";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';

export const ContinueOrCancelModal = props => {
    const isOpen = props.isOpen;
    const { t } = useTranslation();

    return (
        <>
            <Modal size={props.size || ""} isOpen={isOpen} toggle={props.toggle} backdrop="static">
                <ModalHeader className="modal-header white-text">{props.title}</ModalHeader>
                <ModalBody style={{ textAlign: "center" }}>
                    <Row>
                        <Col>
                            <p className="card-description">{props.message}</p>
                        </Col>
                    </Row>
                    <div className="modal-footer justify-content-right">
                        <button className="btn btn-primary" onClick={props.onClick}>{t("button.continue")}</button>
                        <button onClick={props.toggle} className="btn btn-outline-primary">{t("button.cancel")}</button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};
