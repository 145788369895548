import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { InputField } from "../FormComponents/FormFields";
import { CircleLoader } from "../PageFillers/PageLoaders";
import { TitleComponent } from '../common/Title';
import { useTranslation } from 'react-i18next';

const ConfigurationArea = ({
    configuration,
    onSave,
    onChange,
    saving = false,
    errors = {},
    parseConfigData
}
) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (configuration.length === 0)
            parseConfigData();
    }, []);

    return (
        <form onSubmit={onSave}>
            {configuration.length === 0 ? (
                <CircleLoader />
            ) : (
                    <>
                        <TitleComponent title={t("header.configuration")} />
                        {errors.onSave && (
                            <div className="alert alert-danger" role="alert">
                                {errors.onSave}
                            </div>
                        )}
                        <div className="row">
                            <fieldset className="col-md-4 m-0 form-group">
                                <InputField
                                    label={t("inputField.sessionTimeoutByUser")}
                                    type={"number"}
                                    name={configuration.SessionTimeoutByUser.name}
                                    value={configuration.SessionTimeoutByUser.value}
                                    placeholder=" "
                                    handleChange={(event) => {
                                        onChange(event, configuration.SessionTimeoutByUser.id);
                                    }}
                                    min={"1"}
                                    error={errors.sessionTimeoutByUser}
                                />
                            </fieldset>
                            <fieldset className="col-md-2"></fieldset>
                            <fieldset className="col-md-4 m-0 form-group">
                                <InputField
                                    label={t("inputField.sessionTimeoutByThread")}
                                    type={"number"}
                                    name={configuration.SessionTimeoutByThread.name}
                                    value={configuration.SessionTimeoutByThread.value}
                                    placeholder=" "
                                    handleChange={(event) => {
                                        onChange(event, configuration.SessionTimeoutByThread.id);
                                    }}
                                    min={"1"}
                                    error={errors.sessionTimeoutByThread}
                                />
                            </fieldset>
                        </div>
                        <div className="row">
                            <fieldset className="col-md-4 m-0 form-group">
                                <InputField
                                    label={t("inputField.numberOfAPICallsToAttend")}
                                    type={"number"}
                                    name={configuration.NumberAPICalls.name}
                                    value={configuration.NumberAPICalls.value}
                                    placeholder=" "
                                    handleChange={(event) => {
                                        onChange(event, configuration.NumberAPICalls.id);
                                    }}
                                    min={"0"}
                                    error={errors.numberAPICalls}
                                />
                            </fieldset>
                            <fieldset className="col-md-2 textCenter">
                                {t("inputField.or")}
                                </fieldset>
                            <fieldset className="col-md-4 m-0 form-group">
                                <InputField
                                    label={t("inputField.timeBetweenCalls")}
                                    type={"number"}
                                    name={configuration.TimeBetweenCalls.name}
                                    value={configuration.TimeBetweenCalls.value}
                                    placeholder=" "
                                    handleChange={(event) => {
                                        onChange(event, configuration.TimeBetweenCalls.id);
                                    }}
                                    min={"0"}
                                    error={errors.timeBetweenCalls}
                                />
                            </fieldset>
                        </div>
                        <div className="row">
                            <fieldset className="col-md-4 m-0 form-group">
                                <InputField
                                    label={t("inputField.numberOfParallelThreads")}
                                    type={"number"}
                                    name={configuration.NumberParallelThreads.name}
                                    value={configuration.NumberParallelThreads.value}
                                    placeholder=" "
                                    handleChange={(event) => {
                                        onChange(event, configuration.NumberParallelThreads.id);
                                    }}
                                    min={"0"}
                                    max={"10"}
                                    error={errors.numberParallelThreads}
                                />
                            </fieldset>
                        </div>
                        <div className="row">
                            <fieldset className="col-md-4 m-0 form-group">
                                <InputField
                                    label={t("inputField.maxSizeOfTheUploadedFile")}
                                    type={"number"}
                                    name={configuration.MaxSizeFile.name}
                                    value={configuration.MaxSizeFile.value}
                                    placeholder=" "
                                    handleChange={(event) => {
                                        onChange(event, configuration.MaxSizeFile.id);
                                    }}
                                    min={"0"}
                                    error={errors.maxSizeFile}
                                />
                            </fieldset>
                        </div>
                        <div className="row">
                            <fieldset className="col-md-12 m-0 p-0 form-group">
                                <div className="md-form m-0 pull-right clearfix">
                                    <button type="submit" className="btn btn-primary" disabled={saving}>{saving ? t("button.saving") : t("button.save")}</button>
                                </div>
                            </fieldset>
                        </div>
                    </>
                )}
        </form>
    );
};

ConfigurationArea.propTypes = {
    errors: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    saving: PropTypes.bool,
};


export default ConfigurationArea;