import React, { useState, useEffect, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../Auth/AuthContext';
import Layout from '../Layout';
import * as functionUtils from "../../functions/functionUtils"
import { role } from '../../constants/roleConstants';

export default function PrivateRoute({
    component,
    roles,
    ...rest
}) {
    const authService = useContext(AuthContext);
    const [hasPermission, setHasPermission] = useState(false);
    const [readOnlyPermission, setReadOnlyPermission] = useState(true);
    const [waitPermission, setWaitPermission] = useState(true);
    const [waitReadOnlyPermission, setWaitReadOnlyPermission] = useState(true);

    useEffect(() => {
        if (roles) {
            functionUtils.hasPermissionsUserByListRoles(roles, authService.getUserId(), res => { setHasPermission(res); setWaitPermission(false); });
            functionUtils.hasPermissionUser(role.READ_ONLY, authService.getUserId(), res => { setReadOnlyPermission(res); setWaitReadOnlyPermission(false); });
        }
    }, []);

    const renderFunc = (Component) => (props) => {

        if (authService.isAuthenticated()) {
            if (roles && !waitPermission && !waitReadOnlyPermission) {
                if (hasPermission && !readOnlyPermission)
                    return (<Layout showBreadcrumb={true}><Component {...props} /></Layout>);
                else
                    return <Redirect to={'/accessDenied' + window.location.pathname} />
            }
            else
                return (<Layout showBreadcrumb={true}><Component {...props} /></Layout>);
        }
        else {
            // @ts-ignore
            localStorage.setItem('signin_url', window.location.pathname);
            return <Redirect to='/login' />;
        }
    }

    return <Route {...rest} render={renderFunc(component)} />;
}
