import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function batchFileProcessedReducer(state = initialState.batchFileProcessed, action) {
    switch (action.type) {
        case types.LOAD_BATCH_FILE_PROCESSED_SUCCESS:
            return action.batchFileProcessed;
        default:
            return state;
    }
}
