import React from "react";
import { Default } from "react-awesome-spinners";

/**
 * 
 * @param {object} props 
 * @param {string} [props.loadingText] 
 */
export const CircleLoader = ({ loadingText }) => {
    return (
        <>
            <div style={{ position: "height 100%" }}>
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                        fontWeight: "100"
                    }}
                >
                    <Default sizeUnit={"px"} size={60} />
                    {loadingText !== null && loadingText !== undefined ? <h2>{loadingText}</h2> : null}
                </div>
            </div>
        </>
    );
};
