import React, { useState, useContext, useEffect } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext } from '../Auth/AuthContext';
import { role } from '../../constants/roleConstants';
import * as functionUtils from "../../functions/functionUtils"
import './i18n';
import { useTranslation } from 'react-i18next';

export default function Header() {
    const { t, i18n } = useTranslation();
    const authService = useContext(AuthContext);
    const [collapsed, setCollapsed] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [languageOpen, setLanguageOpen] = useState(false);
    const [userName] = useState(authService.getUserName());
    const [isAuth, setIsAuth] = useState(false);
    const [adminPermission, setAdminPermission] = useState(false);
    const [readOnlyPermission, setReadOnlyPermission] = useState(true);
    const defaultLang = 'en-GB';
    const [userLang, setUserLang] = useState(i18n.options.lng);

    const changePass = () => {
        const url = window.location.toString();
        authService.getChangePassword(url);
    }

    let sectionLanguages = i18n.options.preload;

    if (userLang && sectionLanguages.findIndex(item => item === userLang) == -1) {
        setUserLang(defaultLang);
    }

    function languageChange(lang) {
        var culture = lang;

        i18n.changeLanguage(culture);
        setUserLang(culture);

        if (culture != undefined) {
            functionUtils.setCookie("BulkImporterCulture", culture, 365)
        }
    };

    useEffect(() => {
        setIsAuth(authService.isAuthenticated());
        functionUtils.hasPermissionUser(role.ADMIN, authService.getUserId(), res => setAdminPermission(res));
        functionUtils.hasPermissionUser(role.READ_ONLY, authService.getUserId(), res => setReadOnlyPermission(res));
    }, []);

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 navbar-dark bg-primary" light>
                <Container>
                    <NavbarBrand tag={Link} to="/"><img src="/tunstall-logo.png" className="header-tunstall-logo d-inline-block align-top" alt="Tunstall Logo" />Tsp Bulk Importer</NavbarBrand>
                    <NavbarToggler onClick={() => setCollapsed(!collapsed)} className="mr-2" />
                    {
                        isAuth
                            ? (

                                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                                    <ul className="navbar-nav flex-grow">
                                        <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
                                            <DropdownToggle nav caret >
                                                <FontAwesomeIcon icon={faUser} />{` ${userName}`}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem tag="a" onClick={changePass}>{t("header.changePassword")}</DropdownItem>
                                                <DropdownItem tag="a" onClick={() => authService.signOut()}>{t("header.logOut")}</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </ul>
                                    <ul className="navbar-nav flex-grow">
                                        <Dropdown isOpen={languageOpen} toggle={() => setLanguageOpen(!languageOpen)}>
                                            <DropdownToggle nav caret title={t(userLang)}>
                                                {` ${userLang}`}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {(sectionLanguages.map(lang =>
                                                    <DropdownItem tag="a" onClick={() => languageChange(lang)} title={lang}>{t(lang)}</DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </ul>
                                    <ul className="navbar-nav mr-auto">
                                        <NavItem>
                                            <NavLink tag={Link} to="/fileManagement">{t("header.fileManagement")}</NavLink>
                                        </NavItem>
                                        {adminPermission && !readOnlyPermission ?
                                            <NavItem>
                                                <NavLink tag={Link} to="/configuration">{t("header.configuration")}</NavLink>
                                            </NavItem>
                                             : null}
                                        </ul>
                                </Collapse>
                            ) : null
                    }
                </Container>
            </Navbar>
        </header>
    );
}