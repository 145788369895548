import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { loadConfigurations, saveConfiguration } from "../../redux/actions/configurationActions";
import Tabs from '../Tabs/Tabs';
import PropTypes from "prop-types";
import ConfigurationArea from "./ConfigurationArea";
import TemplateManagement from "./TemplateManagement";
import { CircleLoader } from "../PageFillers/PageLoaders";
import * as functionUtils from "../../functions/functionUtils"
import '../../css/custom.css';
import { useTranslation } from 'react-i18next';

function Configuration({
    configurations,
    loadConfigurations,
    saveConfiguration,
    loading,
    ...props
}) {

    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const [saveConfig, setSaveConfig] = useState([]);
    const [configuration, setConfiguration] = useState([]);

    useEffect(() => {
        loadConfigurations();
    }, []);

    function parseConfigData() {
        if (configurations.length > 0) {
            configurations.map(_item => {
                return setConfiguration(prev => ({
                    ...prev,
                    [_item.name]: {
                        id: _item.id,
                        value: _item.value,
                        name: _item.name
                    }
                }));
            });
        }
    }

    function formIsValid() {
        const { SessionTimeoutByUser, SessionTimeoutByThread, NumberAPICalls, TimeBetweenCalls, NumberParallelThreads, MaxSizeFile } = configuration;
        const errors = {};

        if (!SessionTimeoutByUser.value) errors.sessionTimeoutByUser = t("error.sessionTimeoutByUserCannotBeEmpty");
        if (!SessionTimeoutByThread.value) errors.sessionTimeoutByThread = t("error.sessionTimeoutByThreadCannotBeEmpty");
        if (!NumberAPICalls.value) errors.numberAPICalls = t("error.numberAPICallsCannotBeEmpty");
        if (!TimeBetweenCalls.value) errors.timeBetweenCalls = t("error.timeBetweenCallsCannotBeEmpty");
        if (!NumberParallelThreads.value) errors.numberParallelThreads = t("error.numberParallelThreadsCannotBeEmpty");
        if (!MaxSizeFile.value) errors.maxSizeFile = t("error.maxSizeFileCannotBeEmpty");

        setErrors(errors);
        // Form is valid if the errors object still has no properties
        return Object.keys(errors).length === 0;
    }

    function handleChange(event, id) {
        if (event !== undefined) {
            const { name, value } = event.target;
            setConfiguration(prev => ({
                ...prev,
                [name]: {
                    id: id,
                    value: value,
                    name: name
                }
            }));
        }
    }

    function handleSave(event) {
        event.preventDefault();
        if (!formIsValid()) return;

        setSaving(true);
        parseConfiguration();

        saveConfiguration(saveConfig)
            .then(res => handleSaveConfiguration(res))
            .catch(error => {
                setSaving(false);
                setErrors({ onSave: error.message });
            });
    }

    const handleSaveConfiguration = (res) => {
        if (res) {
            loadConfigurations();
            functionUtils.handleSuccess(t("message.saveConfigurationSuccessfull"));
        }
        setSaving(false);
    }

    const parseConfiguration = () => {
        saveConfig.push(configuration.NumberAPICalls);
        saveConfig.push(configuration.SessionTimeoutByUser);
        saveConfig.push(configuration.SessionTimeoutByThread);
        saveConfig.push(configuration.TimeBetweenCalls);
        saveConfig.push(configuration.NumberParallelThreads);
        saveConfig.push(configuration.MaxSizeFile);
    }

    return (
        <div>
            <Tabs>
                <div eventKey={"configurationArea"} label={t("home.configurationArea")} className="tab-pane fade in show active" role="tabpanel">
                    {loading ? (
                        <CircleLoader />
                    ) : (
                            <>
                                <ConfigurationArea
                                    configuration={configuration}
                                    errors={errors}
                                    onChange={handleChange}
                                    onSave={handleSave}
                                    saving={saving}
                                    parseConfigData={parseConfigData}
                                />
                            </>
                        )}
                </div>
                <div eventKey={"templateManagement"} label={t("header.templateManagement")} className="tab-pane fade in" role="tabpanel">
                    <TemplateManagement />
                </div>
            </Tabs>
        </div>
    );
}

Configuration.propTypes = {
    loading: PropTypes.bool.isRequired,
    loadConfigurations: PropTypes.func.isRequired,
    saveConfiguration: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        configurations: state.configuration,
        loading: state.apiCallsInProgress > 0
    };
}

const mapDispatchToProps = {
    loadConfigurations,
    saveConfiguration
}


export default connect(mapStateToProps, mapDispatchToProps)(Configuration);