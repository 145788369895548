import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function identityReducer(state = initialState.identityUsers, action) {
    switch (action.type) {
        case types.LOAD_IDENTITY_USERS_SUCCESS:
            return action.identityUsers;
        default:
            return state;
    }
}
