import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import moment from 'moment'
import TitledNotification from '../components/Toast/TitleNotification';
import { getRolesByUserId } from '../api/bulkAPI';

const SUCCESS = "Success";
const UPLOADFAILURE = "There was an error uploading";

export function handleHttpResponse(toastId, message) {
    if (message.status !== 200) {
        updateToHandleError(toastId, UPLOADFAILURE);
    } else {
        updateToSuccessToast(toastId, SUCCESS);
    }
}

export function updateToSuccessToast(toastId, message) {
    toast.update(toastId, {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
        render: message
    });
}

export function updateToHandleError(toastId, message) {
    toast.update(toastId, {
        type: toast.TYPE.ERROR,
        autoClose: 3000,
        render: message
    });
    throw message;
}

export async function handleResponse(response) {
    if (response.ok) return response.json();
    if (response.status === 400) {
        const message = await response.text();
        return toast.error(message, { autoclose: 5000 });
    }
}

/**
 * @typedef {Object} Exception
 * @property {string} message
 */

/**
 * @typedef {Object} ApiException
 * @property {string} title
 * @property {string} body
 * @property {string} [traceId]
 */

/**
 * @typedef {(Exception|ApiException)} ErrorObject
 * @callback errorCallback
 * @param {ErrorObject} error
 * @returns {(Exception|ApiException)}
 */

/**
 * @param {ErrorObject} message
 * @param {errorCallback} [errorCallback]
 */
export const handleError = (message, errorCallback) => {
    message = errorCallback !== undefined ? errorCallback(message) : message;
    if (message === null || message === undefined) return;

    const exception = /**@type {Exception} */ (message);
    const apiException = /**@type {ApiException} */ (message);

    if (exception.message !== undefined) toast.error(exception.message, { autoClose: false, closeOnClick: false, draggable: false });
    else if (apiException.title !== undefined && apiException.body !== undefined) toast.error(TitledNotification({ content: apiException }), { autoClose: false, closeOnClick: false, draggable: false });
};

export const handleSuccess = message => {
    toast.success(message, { autoClose: 3000 });
};

export const handleWarning = message => {
    toast.warn(message, { autoClose: 3000 });
};

export const handleInfo = message => {
    toast.info(message, { autoClose: false });
};

export function replaceAndHTMLEncode(source, params) {
    $.each(params, function (i, n) {
        n = n.replace("/", "%2F");
        source = source.replace(new RegExp("\\{" + i + "\\}", "g"), n);
    });
    return source;
}

export const getfirstArray = array => {
    if (array == null || array === undefined)
        return null;
    if (array.length > 0)
        return array[0];
};

export const parseSelectOptions = parseOptions => {
    const types = parseOptions.map(_option => {
        const item = {
            value: _option.id,
            label: _option.name,
            disabled: _option.isArchived
        };
        return item;
    });
    return types;
};

export const parseSelectIdentityUserOptions = parseOptions => {
    const types = parseOptions.map(_option => {
        const item = {
            value: _option.subject,
            label: _option.forename + ' ' + _option.surname + ' (' + _option.userName + ')'
        };
        return item;
    });
    return types;
};

export const parseCheckTreeScopes = parseScopes => {
    let scopesList = [];
    parseScopes.filter(scope => scope.isArchived === false).forEach(scope => {
        let _children = parseCheckTreeChildrenScopes(scope.children);
        scopesList.push(
            {
                value: scope.id,
                label: scope.name,
                title: _children !== null ? _children.length : 0,
                children: _children
            });
    });
    return scopesList;
};

function parseCheckTreeChildrenScopes(parseScopes) {
    if (parseScopes !== null) {
        return parseScopes.filter(scope => scope.isArchived === false).map(children => {
            let childrenScope = children.length ? children : children.children;
            let _children = parseCheckTreeChildrenScopes(childrenScope);
            return {
                value: children.id,
                label: children.name,
                title: _children !== null ? _children.length : 0,
                children: _children
            }
        });
    }
    else
        return null
};

export const hasPermissionUser = (rolesPermission, userId, cb) => {
    getRolesByUserId(userId)
        .then(_rolesUser => {
            if (_rolesUser && _rolesUser.length > 0) {
                if (_rolesUser.filter(role => rolesPermission === role.id.toUpperCase()).length > 0) {
                    cb(true);
                }
                else {
                    cb(false);
                }
            }
            else {
                cb(false);
            }
        });
};

export const hasPermissionsUserByListRoles = (rolesPermissions, userId, cb) => {
    getRolesByUserId(userId)
        .then(_rolesUser => {
            if (_rolesUser && _rolesUser.length > 0) {
                var hasPermision = _rolesUser.filter(function (role) {
                    return rolesPermissions.indexOf(role.id.toUpperCase()) > -1;
                });
                if (hasPermision.length > 0) {
                    cb(true);
                }
                else {
                    cb(false);
                }
            }
            else {
                cb(false);
            }
        });
};

export const isGuid = value => {
    var regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
    var match = regex.exec(value);
    return match != null;
}

export const formatDate = date => {
    let cultureCookies = getCookie("BulkImporterCulture");
    const userLang = cultureCookies || navigator.language || navigator.userLanguage;

    if (userLang === "sv" || userLang === "en-US") return moment(date).format('YYYY-MM-DD');
    else return moment(date).format('DD/MM/YYYY') === 'Invalid date' ? date : moment(date).format('DD/MM/YYYY');
}

export const formatDateTime = date => {
    let cultureCookies = getCookie("BulkImporterCulture");
    const userLang = cultureCookies || navigator.language || navigator.userLanguage;

    var localTime = moment.utc(date).toDate();
    if (userLang === "sv" || userLang === "en-US") return moment(localTime).format('YYYY-MM-DD HH:mm:ss');
    else return moment(localTime).format('DD/MM/YYYY HH:mm:ss') === 'Invalid date' ? date : moment(localTime).format('DD/MM/YYYY HH:mm:ss');
}

export const getCookie = name => {
    var re = new RegExp(name + "=([^;]+)");
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
}

export const setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const translateSelectInput = (array, t) => {

    const translate = array.map(_option => {
        const item = {
            value: _option.value,
            label: t(_option.label),
            disabled: _option.disabled
        };
        return item;
    });

    return translate;
}

export const getLanguageText = userLang => {
    switch (userLang) {
        case "es":
        case "es-ES":
            return "es-ES";
        case "ca":
        case "ca-ES":
            return "ca-ES";
        case "eu":
        case "eu-ES":
            return "eu-ES";
        case "fi":
        case "fi-FI":
            return "fi-FI";
        case "fr":
        case "fr-FR":
            return "fr-FR";
        case "sv":
        case "sv-SE":
            return "sv-SE";
        case "da":
        case "da-DK":
            return "da";
        case "de":
        case "de-DE":
            return "de-DE";
        case "nl":
        case "nl-NL":
            return "nl-NL";
        default:
            return userLang;
    }
}