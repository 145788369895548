export const LOAD_SUMMARY_SUCCESS = "LOAD_SUMMARY_SUCCESS";
export const LOAD_BATCH_FILE_DETAIL_SUCCESS = "LOAD_BATCH_FILE_DETAIL_SUCCESS";
export const LOAD_BATCH_FILE_PROCESSED_SUCCESS = "LOAD_BATCH_FILE_PROCESSED_SUCCESS";
export const LOAD_STATUS_SUCCESS = "LOAD_STATUS_SUCCESS";
export const LOAD_ROLES_SUCCESS = "LOAD_ROLES_SUCCESS";
export const LOAD_ROLES_USER_SUCCESS = "LOAD_ROLES_USER_SUCCESS";
export const LOAD_BULK_IMPORTER_SEARCH_SUCCESS = "LOAD_BULK_IMPORTER_SEARCH_SUCCESS";
export const LOAD_BULK_IMPORTER_ADVANCED_SEARCH_SUCCESS = "LOAD_BULK_IMPORTER_ADVANCED_SEARCH_SUCCESS";
export const LOAD_TEMPLATE_TYPES_SUCCESS = "LOAD_TEMPLATE_TYPES_SUCCESS";
export const LOAD_TEMPLATES_SUCCESS = "LOAD_TEMPLATES_SUCCESS";
export const UPLOADED_TEMPLATES_SUCCESS = "UPLOADED_TEMPLATES_SUCCESS";
export const LOAD_IDENTITY_USERS_SUCCESS = "LOAD_IDENTITY_USERS_SUCCESS";
export const LOAD_SM_SCOPES_SUCCESS = "LOAD_SM_SCOPES_SUCCESS";
export const LOAD_CONFIGURATIONS_SUCCESS = "LOAD_CONFIGURATIONS_SUCCESS";
export const UPDATE_CONFIGURATIONS_SUCCESS = "UPDATE_CONFIGURATIONS_SUCCESS";
export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";