import Fetch from './fetchUtils';
import { TokenModel } from '../components/Auth/types';
import UrlUtils from '../functions/urlUtils';
const rootUrl = 'connect/token';

/**
 * @typedef {import('../functions/functionUtils').ApiException} ApiException
 */

export default class TokenApi {
    /**
     * 
     * @param {string} authorizationCode
     * @param {string} idToken
     * @returns {Promise<TokenModel | null>}
     */
    async getToken(authorizationCode, idToken) {
        const response = await Fetch.post(`${rootUrl}?redirectUri=${UrlUtils.getSigninUrl()}`, authorizationCode, true);
        return response === null || response === undefined ? null : new TokenModel(response, idToken);
    }

    /**
     * 
     * @param {string} refreshToken 
     * @param {string} idToken
     * @returns {Promise<TokenModel | null>}
     */
    async refreshToken(refreshToken, idToken) {
        const response = await Fetch.post(`${rootUrl}/refresh`, refreshToken, true);
        return response === null || response === undefined ? null : new TokenModel(response, idToken);
    }
}