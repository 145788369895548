import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCheckSquare, faSquare, faChevronRight, faChevronDown, faPlusSquare, faMinusSquare, faFolder, faFolderOpen, faFile } from "@fortawesome/free-solid-svg-icons";
import { Scrollbars } from "react-custom-scrollbars";
import { useTable, usePagination } from 'react-table'
import _ from "lodash";
import Pagination from "react-js-pagination";
import { useTranslation } from 'react-i18next';
import * as functionUtils from "../../functions/functionUtils";
import moment from 'moment'

export const InputField = props => {
    let wrapperClass = "form-group";
    let labelClass = "bmd-label-floating";
    let inputClass = "form-control " + props.classWith;

    if (props.error && props.error.length > 0) {
        labelClass += " label_error"
        inputClass += " input_error"
        wrapperClass += " has-error";
    }
    return (
        <>
            <div className={wrapperClass}>
                <label
                    className={labelClass}
                    style={
                        props.required
                            ? props.value
                                ? {}
                                : { color: "rgba(212, 0, 0, 0.7)" }
                            : {}
                    }
                >
                    {props.label}
                </label>
                <input
                    type={props.type || "text"}
                    name={props.name || ""}
                    className={inputClass}
                    placeholder={props.placeholder || "Unknown..."}
                    value={props.value || ""}
                    onChange={props.handleChange || null}
                    required={props.required}
                    autoFocus={props.autoFocus}
                    disabled={(props.disabled) ? "disabled" : ""}
                    min={props.min || ""}
                    max={props.max || ""}
                    onKeyPress={props.onKeyPress}
                />
                {props.error && <div className="validation_error">{props.error}</div>}
            </div>
        </>
    );
};

export const InputFile = props => {
    const { t } = useTranslation();
    let wrapperClass = "form-group";
    let wrapperfileClass = "file-field";
    let labelClass = "bmd-label-floating";
    let inputClass = "form-control";

    if (props.error && props.error.length > 0) {
        labelClass += " label_error"

        wrapperfileClass += " input_error";
    }

    return (
        <>
            <div className={wrapperClass}>
                <label className={labelClass}>{props.label}</label>
                <div className={wrapperfileClass}>
                    <div className="btn btn-primary btn-sm float-right">
                        <span>{props.buttonText}</span>
                        <input type={"file"}
                            onChange={props.handleChange || null}
                            required={props.required}
                            autoFocus={props.autoFocus}
                            disabled={(props.disabled) ? "disabled" : ""}
                            title={t("inputField.noFileChosen") }
                        />
                    </div>
                    <div className="file-path-wrapper">
                        <input
                            className={inputClass}
                            type={"text"}
                            name={props.name || ""}
                            placeholder={props.placeholder}
                            required={props.required}
                            autoFocus={props.autoFocus}
                            disabled={(props.disabled) ? "disabled" : ""}
                        />
                    </div>
                </div>
                {props.error && <div className="validation_error">{props.error}</div>}
            </div>
        </>
    );
};


export const CreationSelect = props => {
    return (
        <>
            <div className="form-group">
                <label className="bmd-label-floating">{props.label}</label>
                <CreatableSelect placeholder="Select Role or Type to Create..." />
            </div>
        </>
    );
};

export const SelectInput = props => {
    const { t } = useTranslation();
    let wrapperClass = "form-group";
    let labelClass = "bmd-label-floating";
    let inputClass = "";

    if (props.error && props.error.length > 0) {
        labelClass += " label_error"
        inputClass += " input_error"
        wrapperClass += " has-error";
    }
    return (
        <>
            <div className={wrapperClass}>
                <label className={labelClass} htmlFor={props.name}>{props.label}</label>
                <Select
                    placeholder={t("selectInput.chooseYourOption")}
                    className={inputClass}
                    name={props.name || ""}
                    value={
                        props.selected &&
                        props.options.filter(item => item.value === props.selected)
                    }
                    isOptionDisabled={option => option.disabled}
                    options={props.options}
                    onChange={props.onChange}
                    selected={props.templateTypeId}
                />
                {props.error && <div className="validation_error">{props.error}</div>}
            </div>
        </>
    );
};

export const TreeView = props => {
    const { t } = useTranslation();
    let labelClass = "bmd-label-floating";
    const [heightScroll] = useState("200");
    const [isMultiScope] = useState(props.multiScope !== undefined ? props.multiScope : true)
    const [applyChilds, setApplyChilds] = useState(false);
    const divStyle = {
        height: heightScroll + 'px',
    };

    if (props.error && props.error.length > 0) {
        labelClass += " label_error"
    }

    const [clicked, setClicked] = useState({});
    const [searchInfo, setSearchInfo] = useState({
        keyword: "",
        expanded: props.expanded
    });

    function getHighlightText(text, keyword) {
        const startIndex = text.indexOf(keyword);
        return startIndex !== -1 ? (
            <span>
                {text.substring(0, startIndex)}
                <span style={{ color: "#2cb664" }}>
                    {text.substring(startIndex, startIndex + keyword.length)}
                </span>
                {text.substring(startIndex + keyword.length)}
            </span>
        ) : (
                <span>{text}</span>
            );
    };

    const keywordFilter = (nodes, keyword) => {
        let newNodes = [];

        for (let n of nodes) {
            if (n.children) {
                const nextNodes = keywordFilter(n.children, keyword);
                if (nextNodes.length > 0) {
                    n.children = nextNodes;
                } else if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
                    n.children = nextNodes.length > 0 ? nextNodes : [];
                }
                if (
                    nextNodes.length > 0 ||
                    n.label.toLowerCase().includes(keyword.toLowerCase())
                ) {
                    n.label = getHighlightText(n.label, keyword);
                    newNodes.push(n);
                }
            } else {
                if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
                    n.label = getHighlightText(n.label, keyword);
                    newNodes.push(n);
                }
            }
        }
        return newNodes;
    };

    const onSearchInputChange = (event, searchedNodes) => {
        let value = event.target.value;
        setSearchInfo(prev => {
            if (prev.keyword.trim() && !value.trim()) {
                return {
                    expanded: [],
                    keyword: value
                };
            }
            return {
                expanded: getAllValuesFromNodes(searchedNodes, true),
                keyword: value
            };
        });
    };

    function handleApplyChildsChange(event) {
        setApplyChilds(event.target.checked);
    }

    const getAllValuesFromNodes = (nodes, firstLevel) => {
        if (firstLevel) {
            const values = [];
            for (let n of nodes) {
                values.push(n.value);
                if (n.children) {
                    values.push(...getAllValuesFromNodes(n.children, false));
                }
            }
            return values;
        } else {
            const values = [];
            for (let n of nodes) {
                values.push(n.value);
                if (n.children) {
                    values.push(...getAllValuesFromNodes(n.children, false));
                }
            }
            return values;
        }
    };

    const handleScopeExpand = event => {
        let value = event;

        setSearchInfo(prev => {
            return { ...prev, expanded: value };
        });

    };

    const onCheck = checked => {
        if (!applyChilds && !isMultiScope) {
            if (checked.length > 1) {
                return checked.map(newCheck => {
                    return props.checked.map(oldCheck => {
                        if (newCheck !== oldCheck)
                            return props.onCheck([newCheck]);
                    });
                });
            }
        }
        return props.onCheck(checked);
    };

    const onClick = clicked => {
        if (clicked) setClicked({ clicked });
    }

    let searchedNodes = searchInfo.keyword.trim()
        ? keywordFilter(_.cloneDeep(props.nodes), searchInfo.keyword)
        : props.nodes;

    return (
        <>
            <div className="form-group">
                <div className="row m-0 p-0 mt-2">
                    <fieldset className="md-form col-sm-8 row p-0 m-0">
                        <InputField
                            placeholder={t("inputField.insertSearchScopes")}
                            value={searchInfo.keyword}
                            handleChange={(event) => {
                                onSearchInputChange(event, searchedNodes);
                            }}
                        />
                    </fieldset>
                    {isMultiScope ? (
                        <>
                            <fieldset className="col-sm-1 m-0 form-group" >
                                <InputField
                                    classWith="width-Check"
                                    type={"checkbox"}
                                    handleChange={handleApplyChildsChange}
                                />
                            </fieldset>
                            <fieldset className="col-sm-4 m-0 form-group margin-top-label" >
                                <label>{t("inputField.applyAllChilds")}</label>
                            </fieldset>
                        </>
                    ) : (<></>
                        )}
                    <fieldset className="col-sm-3 row p-0 m-0 margin-top">
                        <div className="tooltip-wrapper tooltip-wrapper-without-margen" data-toggle="tooltip" data-placement="top" title={t("inputField.uncheckAll")}>
                            <button className="btn-floating-custom btn-primary actionButton-xl" onClick={props.uncheckAllTreeView}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>
                    </fieldset>
                </div>
                <label className={labelClass}>{props.label}</label>
                <Scrollbars style={divStyle}>
                    <CheckboxTree
                        icons={{
                            check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon={faCheckSquare} />,
                            uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={faSquare} />,
                            halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon={faCheckSquare} />,
                            expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon={faChevronRight} />,
                            expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon={faChevronDown} />,
                            expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon={faPlusSquare} />,
                            collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon={faMinusSquare} />,
                            parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon={faFolder} />,
                            parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon={faFolderOpen} />,
                            leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faFile} />
                        }}
                        checkModel={"all"}
                        name={props.name || ""}
                        nodes={searchedNodes}
                        checked={props.checked}
                        expanded={searchInfo.expanded}
                        onCheck={onCheck}
                        onExpand={handleScopeExpand}
                        noCascade={!isMultiScope || !applyChilds}
                        showNodeIcon={false}
                        showNodeTitle
                        expandOnClick
                        onClick={onClick}
                    />
                </Scrollbars>
                {props.error && <div className="validation_error">{props.error}</div>}
            </div>
        </>
    );
};

export function ReactTable({ columns, data }) {
    const { t } = useTranslation();
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        usePagination
    )

    const total = data.length;
    const hiddenPagination = data.length === 0;
    const from = ((pageIndex) * pageSize) + 1;
    const sum = from + pageSize;
    const to = sum > data.length ? sum - (sum - data.length) : sum;
    const [activePage, setActivePage] = useState(1);
    const totalNumberPages = Math.ceil(total / pageSize);

    const handlePageChange = page => {
        setActivePage(Number(page));
        gotoPage(page - 1);
    }

    const handleGoToPageChange = event => {
        const page = event.target.value > totalNumberPages ? totalNumberPages : Number(event.target.value);
        setActivePage(page === 0 ? 1 : page);
        gotoPage(page === 0 ? 0 : page - 1);
    }

    function handlePageSizeChange(event) {
        const value = event.value;
        setActivePage(1);
        setPageSize(Number(value));
    };

    const options = [
        { value: 10, label: t("selectInput.10Rows") },
        { value: 25, label: t("selectInput.25Rows") },
        { value: 50, label: t("selectInput.50Rows") },
        { value: 100, label: t("selectInput.100Rows")}
    ];

    return (
        <>
            <div className="panel panel-default">
                <div className="panel-body">
                    <div className="dataTables_length width-Select-rows" hidden={hiddenPagination}>
                        <SelectInput
                            name={"pageSize"}
                            defaultOption={t("inputField.selectTemplateType")}
                            onChange={handlePageSizeChange}
                            options={options}
                            selected={pageSize}
                        />
                    </div>
                    <div className="form-group">
                        <div className="table-responsive">
                            <table className='table table-bordered table-striped' aria-labelledby="tabelLabel" {...getTableProps()}>
                                <thead className="#bbdefb blue lighten-3 headerTableText breakWhiteSpace">
                                    <tr {...headerGroups[1].getHeaderGroupProps()}>
                                        {headerGroups[1].headers.map(column => (
                                            <th width={column.width} {...column.getHeaderProps()}>{column.render('Header')}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.length === 0 ? (
                                        <tr><td colSpan={headerGroups[1].headers.length} className="dataTables_empty">{t("dataTable.empty")}</td></tr>
                                    ) :
                                    (page.map((row, i) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => {
                                                    if (moment(cell.value, 'DD/MM/YYYY', true).isValid()) {
                                                        return <td {...cell.getCellProps()}>{functionUtils.formatDate(cell.value)}</td>
                                                    }
                                                    else if (moment(cell.value, 'DD/MM/YYYY HH:mm:ss', true).isValid()) {
                                                        return <td {...cell.getCellProps()}>{functionUtils.formatDateTime(cell.value)}</td>
                                                    }
                                                    else if (functionUtils.isGuid(cell.value)){
                                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                    }
                                                    else {
                                                        return <td {...cell.getCellProps()}>{t(cell.value)}</td>
                                                    }
                                                })}
                                            </tr>
                                        )
                                    }))}
                                </tbody>
                            </table>
                        </div>
                        <div hidden={hiddenPagination}>
                            {`${t("dataTable.footer.showing")} `} {from} {` ${t("dataTable.footer.to")} `} {to} {` ${t("dataTable.footer.of")} `} {total} {` ${t("dataTable.footer.entries")}`}
                            <div className="pagination pull-right">
                                <div className="row">
                                    <label>{`${t("dataTable.pagination.page")} `}</label>
                                    <input
                                        className="form-control width-Select-rows"
                                        type={"number"}
                                        name={"goToPage"}
                                        value={activePage}
                                        onChange={handleGoToPageChange}
                                        min={1}
                                        max={totalNumberPages}
                                    />
                                    <Pagination
                                        hideNavigation
                                        firstPageText={t("dataTable.pagination.first")}
                                        lastPageText={t("dataTable.pagination.last")}
                                        activePage={activePage}
                                        itemsCountPerPage={pageSize}
                                        totalItemsCount={total}
                                        pageRangeDisplayed={5}
                                        onChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
