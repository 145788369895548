const getHostUrl = () => `${window.location.protocol}//${window.location.host}`;
const getSigninUrl = () => `${getHostUrl()}/signin`;
const getSignoutUrl = () => `${getHostUrl()}/signout`;

const UrlUtils = {
    getHostUrl,
    getSigninUrl,
    getSignoutUrl
};

export default UrlUtils;