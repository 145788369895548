import moment from 'moment';
import TokenApi from '../../api/tokenApi';
import { TokenModel } from './types';
import AuthorizationApi from '../../api/authorizationApi';
import UrlUtils from '../../functions/urlUtils';

export default class AuthService {
    constructor() {
        this.api = new TokenApi();
        this.authorizeApi = new AuthorizationApi();
    }

    /**
     * @param {string} authorizationCode
     * @param {string} idToken
     * @returns {Promise<void>}
     */
    async signIn(authorizationCode, idToken) {
        const token = await this.api.getToken(authorizationCode, idToken);
        if (token !== null) this.saveToken(token);
    }

    /**
     * @returns {void}
     */
    signOut() {
        const { id_token } = this.getToken();
        sessionStorage.removeItem("token");
        sessionStorage.removeItem('tokenRefresh');

        this.authorizeApi.getEndSession().then(({ url }) => {
            const query = `?id_token_hint=${encodeURIComponent(id_token)}&post_logout_redirect_uri=${encodeURIComponent(UrlUtils.getSignoutUrl())}`;
            // @ts-ignore
            window.location = `${url}${query}`;
        });
    }

    /**
     * @returns {boolean}
     */
    isAuthenticated() {
        const token = this.getToken();
        if (token === null) {
            return false;
        }

        return this.getTokenTimeToExpire(token) > 0 && token.access_token !== null && token.access_token !== undefined;
    }

    /**
     * @returns {string} username logged
     */
    getUserName() {
        const token = this.getToken();
        const name = token !== null && token.profile !== null && token.profile !== undefined
            ? token.profile.name
            : 'No user profile';

        return name;
    }

    /**
     * @returns {string} userId logged
     */
    getUserId() {
        const token = this.getToken();
        const sub = token !== null && token.profile !== null && token.profile !== undefined
            ? token.profile.sub
            : null;

        return sub;
    }

    /**
     * @returns {Promise}
     */
    async refreshToken() {
        if (sessionStorage.getItem('tokenRefresh') !== null) return;

        sessionStorage.setItem('tokenRefresh', '');
        const { refresh_token, id_token } = this.getToken() || {};
        if (refresh_token === undefined) return;

        const token = await this.api.refreshToken(refresh_token, id_token);
        if (token !== null && token !== undefined) this.saveToken(token);
        sessionStorage.removeItem('tokenRefresh');
    }

    /**
     *
     * @param {TokenModel} token
     * @returns {void}
     * @private
     */
    saveToken(token) {
        sessionStorage.setItem("token", JSON.stringify(token));
        sessionStorage.removeItem('tokenRefresh');
    }

    /**
     *
     * @returns {TokenModel}
     * @private
     */
    getToken() {
        return JSON.parse(sessionStorage.getItem("token"));
    }

    async getAccessToken() {
        let token = this.getToken();

        const timeToExpire = this.getTokenTimeToExpire(token);
        if (timeToExpire < 60 && timeToExpire > 0) {
            await this.refreshToken();
            token = this.getToken();
        }

        if (token === null || !this.isAuthenticated()) return null;
        return token.access_token;
    }

    /**
     * 
     * @param {TokenModel} token 
     * @returns {number} Seconds to expire the token
     */
    getTokenTimeToExpire(token) {
        if (token === null || token === undefined || token.expires_at === null || token.expires_at === undefined) return 0;

        const now = moment(Date.now());
        const expiresAt = moment(token.expires_at);
        return expiresAt.diff(now, 'seconds');
    }

    /**
     * @param {string} redirectUrl
     * @returns {void}
     */
    getChangePassword(redirectUrl) {
        // @ts-ignore
        this.authorizeApi.getChangePassword(redirectUrl).then(({ url }) => window.location = url);
    }

    /**
     * @returns {void}
     */
    authorize() {
        // @ts-ignore
        this.authorizeApi.getAuthorization().then(({ url }) => window.location = `${url}&redirect_uri=${encodeURIComponent(UrlUtils.getSigninUrl())}`);
    }
}