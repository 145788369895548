import './common/i18n';
import React, { useState, useEffect, useContext } from 'react';
import { connect } from "react-redux";
import { loadSummary } from "../redux/actions/bulkActions";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { faFileExcel, faFile, faTasks, faCogs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircleLoader } from "./PageFillers/PageLoaders";
import 'react-circular-progressbar/dist/styles.css';
import '../css/home.css';
import { TitleComponent } from '../components/common/Title';
import { role } from '../constants/roleConstants'
import { AuthContext } from './Auth/AuthContext';
import { useTranslation } from 'react-i18next';
import * as functionUtils from "../functions/functionUtils"

function Home({
    batchFileSummary,
    loadSummary,
    loading
}) {
    const { t } = useTranslation();
    const [adminPermission, setAdminPermission] = useState(false);
    const [readOnlyPermission, setReadOnlyPermission] = useState(true);
    const authService = useContext(AuthContext);

    useEffect(() => {
        loadSummary();
        functionUtils.hasPermissionUser(role.ADMIN, authService.getUserId(), res => setAdminPermission(res));
        functionUtils.hasPermissionUser(role.READ_ONLY, authService.getUserId(), res => setReadOnlyPermission(res));

        const interval = setInterval(() => {
            loadSummary();
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className="text-center pb-5 pt-10px">
                {loading ? (
                    <CircleLoader />
                ) : (
                        <>
                            <TitleComponent title="Home" />
                            <fieldset className="container">
                                <div className="row">
                                    <div className="col-md-6 pl-1 pr-1">
                                        <fieldset className="container border rounded text-left home-section">
                                            <div className="section-scheduler-title home-ico-color-gold"><p>{t("home.uploadedFiles")}</p></div>
                                            <div className="fa-4x mt-15px">
                                                <div className="section-sub-ico" data-toggle="tooltip" title={t("home.uploadedFiles")}>
                                                    <FontAwesomeIcon className="home-ico-color-gold" icon={faFileExcel} />
                                                </div>
                                            </div>
                                            <div className="section-scheduler-subtitle home-ico-color-gold"><p>{`${batchFileSummary.uploadedFile} `}{t("home.uploadedFiles")}</p></div>
                                        </fieldset>
                                    </div>

                                    <div className="col-md-6 pl-1 pr-1">
                                        <fieldset className="container border rounded text-left home-section">
                                            <div className="section-scheduler-title home-ico-color-green"><p>{t("home.inProgressFiles")}</p></div>
                                            <div className="fa-4x mt-15px">
                                                <div className="section-sub-ico" data-toggle="tooltip" title={t("home.inProgressFiles")}>
                                                    <FontAwesomeIcon className="home-ico-color-green" icon={faTasks} />
                                                </div>
                                            </div>
                                            <div className="section-scheduler-subtitle home-ico-color-green"><p>{`${batchFileSummary.inProgressFile} `}{t("home.inProgressFiles")}</p></div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 pl-1 pr-1">
                                        <fieldset className="container border rounded text-left home-section">
                                            <div className="section-scheduler-title home-ico-color-red"><p>{t("home.fileManagementArea")}</p></div>
                                            <div className="fa-4x mt-15px">
                                                <div className="section-sub-ico" data-toggle="tooltip" title={t("home.fileManagementArea")}>
                                                    <Link className="home-ico-color-red" to="/fileManagement"><FontAwesomeIcon icon={faFile} /></Link>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    {adminPermission && !readOnlyPermission ?
                                        <div className="col-md-6 pl-1 pr-1">
                                            <fieldset className="container border rounded text-left home-section">
                                                <div className="section-scheduler-title home-ico-color-purple"><p>{t("home.configurationArea")}</p></div>
                                                <div className="fa-4x mt-15px">
                                                    <div className="section-sub-ico" data-toggle="tooltip" title={t("home.configurationArea")}>
                                                        <Link className="home-ico-color-purple" to="/configuration"><FontAwesomeIcon icon={faCogs} /></Link>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    : null}
                                </div>
                            </fieldset>
                        </>
                    )}
            </div>
        </>
    );
}

Home.propTypes = {
    batchFileSummary: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loadSummary: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const loading = state.summary.performanceStatus === undefined;

    return {
        batchFileSummary: state.summary,
        loading
    };
}

const mapDispatchToProps = {
    loadSummary
}


export default connect(mapStateToProps, mapDispatchToProps)(Home);