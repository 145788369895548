import React from "react";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import { CircleLoader } from "../PageFillers/PageLoaders";
import { useTranslation } from 'react-i18next';

export const AddReasonModal = props => {
    const isOpen = props.isOpen;
    const loading = props.loading !== null ? props.loading : false;
    const { t } = useTranslation();

    return (
        <>
            {loading ? (
                <CircleLoader />
            ) : (
                    <>
                        <Modal size={props.size || ""} isOpen={isOpen} toggle={props.toggle} backdrop="static">
                            <ModalHeader className="modal-header white-text">{props.title}</ModalHeader>
                            <ModalBody style={{ textAlign: "left" }}>
                                <Row>
                                    <Col>
                                        <p className="card-description">{props.message}</p>
                                        <div className="form-group shadow-textarea">
                                            <textarea className="form-control z-depth-1" name={props.name}
                                                rows="5"
                                                value={props.value || ""}
                                                onChange={props.handleChange || null}>
                                            </textarea>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="modal-footer justify-content-right">
                                    <button className="btn btn-primary" onClick={props.onClick}>{props.label}</button>
                                    <button onClick={props.toggle} className="btn btn-outline-primary">{t("button.cancel")}</button>
                                </div>
                            </ModalBody>
                        </Modal>
                    </>
                )}
        </>
    );
};
