import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tab from './Tab';

class Tabs extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            activeTab: this.props.children[0].props.eventKey,
        };
    }

    onClickTabItem = (tab) => {
        this.setState({ activeTab: tab });
    }

    render() {
        const {
            onClickTabItem,
            props: {
                children,
            },
            state: {
                activeTab,
            }
        } = this;

        return (
            <div className="container clearfix pb-5">
                <div id="summary-menu">
                    <ul className="nav nav-tabs nav-justified navbar-dark bg-primary">
                        {children.map((child) => {
                            const { label, eventKey } = child.props;
                            return (
                                <Tab
                                    activeTab={activeTab}
                                    eventKey={eventKey}
                                    label={label}
                                    onClick={() => onClickTabItem(eventKey)}
                                />
                            );
                        })}
                    </ul>
                </div>
                <div className="tab-content card">
                    {children.map((child) => {
                        if (child.props.eventKey !== activeTab) return undefined;
                        return child.props.children;
                    })}
                </div>
            </div>
        );
    }
}

export default Tabs;