import React from 'react';
import Helmet from 'react-helmet';

const TitleComponent = ({ title }) => {
    var Title = title + ' - TSP - Bulk Importer';
    return (
        <Helmet>
            <title>{Title}</title>
        </Helmet>
    );
};

export { TitleComponent };