export const batchFileState = {
    UPLOADED_WITH_ERRORS: '71410B22-B05F-4402-AD11-031FD178CF43',
    IN_PROGRESS: '70FD724D-AD5B-4072-9DB7-5702D1C5668A',
    UPLOADED: '1DCD1F68-EC84-4CE9-AB1A-73104149E647',
    ADDED: 'ED067972-C367-4405-AD43-C67BE7298D3A',
    STOPPED: '7D87CACE-38AC-4108-A652-EC9B1CCD02B3',
    PAUSED: 'DFA0EBF6-8F8B-4A40-ADFE-EFFA6FB04CBD',
    RESUME_PROCCESSING: 'DF091005-9CE4-49E8-A39F-497D9139DCA3',
    THREAD_WITH_ERRORS: '28509C8C-52A1-4DAD-888B-1A396975D2A9'
}

export const batchFileProccessState = {
    SUCCESSFUL: '3A9E8ED4-35DA-406E-B3F1-44B58C41742C',
    UNSUCCESSFUL: 'A6EFEA67-70DA-4B0F-A0B6-FE611DC2E3E4'
}

export const batchFileConfiguration = {
    NUMBER_API_CALLS: '759284E3-28A0-48E4-9422-2AD45E3F9371',
    SESSION_TIMEOUT_BY_USER: '6F994D23-0E4B-4B78-BB8D-67C208EF7C80',
    SESSION_TIMEOUT_BY_THREAD: '363B7A81-E28B-4778-B417-69AC66191F91',
    TIME_BETWEEN_CALLS: 'CEB318A5-E8EE-402C-88F3-A4ECCA78F865',
    NUMBER_PARALLEL_THREADS: 'B213426E-5F1B-4A44-BA54-A570711609CB',
    MAX_SIZE_FILE: '8EC7327B-BD82-44C0-A633-B38C2D4915DD'
}

export const templateState = {
    ACTIVE: '641FDF49-6643-41E4-A033-0882A0885DDD',
    CLOSE: 'DCD7D6D1-8CB8-4432-A78A-D74EFD92F3D1'
}
